import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@paljs/ui/Button";

import _ from "lodash";

import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import produce from "immer";
import { SettingsContext } from "../../context/admin";
import { Card, CardBody, CardHeader, CardFooter } from "@paljs/ui/Card";
import { InputGroup } from "@paljs/ui/Input";
import { useOrderSettings } from "../../mutations";
import { GlobalContext } from "../../context/admin";

const OrderSettings = () => {
  const { orderSettings, setOrderSettings } = useContext(SettingsContext);
  const { setData: setMutationData } = useOrderSettings();

  const [initData, setInitData] = useState({});
  const [toSave, setToSave] = useState(false);
  const { restaurant } = useContext(GlobalContext);

  const onChangeOrderIDFormat = ({ target: { value, type, name } }) => {
    if (_.isEmpty(initData)) setInitData(orderSettings);

    const updatedSettings = produce(orderSettings, (draft) => {
      draft[name] = value;
    });
    setOrderSettings(updatedSettings);
    setToSave(!_.isEqual(initData, updatedSettings));
  };

  const onSave = () => {
    setMutationData({
      variables: {
        prefixId: orderSettings.idFormatPrefix,
        suffixId: orderSettings.idFormatSuffix,
        startAt: parseInt(orderSettings.idStartAt),
        restaurantId: restaurant.id,
      },
    });
    setToSave(false);
  };

  return (
    <>
      <Card style={{ minHeight: "280px" }}>
        <CardHeader>
          <Row>
            <Col breakPoint={{ md: 8 }}>EDIT ORDER ID FORMAT (OPTIONAL)</Col>
            <Col breakPoint={{ md: 4 }}>
              <Button
                size="Small"
                status="Success"
                shape="SemiRound"
                appearance="filled"
                fullWidth
                disabled={!toSave}
                pulse={toSave}
                onClick={onSave}
              >
                Save
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              Order numbers start at {orderSettings.idStartAt} by default. While
              you can’t change the order number itself, you can add a prefix or
              suffix to create IDs like "EN1001" or "1001-A."
            </Col>
          </Row>
          <StyledRow>
            <Col breakPoint={{ md: 6 }}>
              <InputGroup>
                <input
                  type="text"
                  name="idFormatPrefix"
                  placeholder="prefix"
                  value={orderSettings.idFormatPrefix || ""}
                  onChange={onChangeOrderIDFormat}
                />
              </InputGroup>
            </Col>
            <Col breakPoint={{ md: 6 }}>
              <InputGroup>
                <input
                  type="text"
                  name="idFormatSuffix"
                  placeholder="suffix"
                  value={orderSettings.idFormatSuffix || ""}
                  onChange={onChangeOrderIDFormat}
                />
              </InputGroup>
            </Col>
          </StyledRow>
        </CardBody>
        <CardFooter>
          <Row>
            <Col>
              Your order ID will appear as {orderSettings.idFormatPrefix}
              {orderSettings.idStartAt}
              {orderSettings.idFormatSuffix}, {orderSettings.idFormatPrefix}
              {orderSettings.idStartAt + 1}
              {orderSettings.idFormatSuffix}, {orderSettings.idFormatPrefix}
              {orderSettings.idStartAt + 2}
              {orderSettings.idFormatSuffix} ...
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

const StyledRow = styled(Row)`
  padding-bottom: 0.5rem;
  padding-top: 2.5rem;
`;

export default OrderSettings;
