import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { styled } from "@mui/material/styles";

import { Button } from "@paljs/ui/Button";

import { Card, CardBody, CardFooter, CardHeader } from "@paljs/ui/Card";
import StrapiImage, {
  STRAPI_IMAGE_SIZES,
} from "../../../components/StrapiImage";
import OrderSummary from "../OrderSummary";
import { usePriceFormat } from "../../../hooks";
import { BreakpointContext } from "../../../context";
import { SettingsContext } from "../../../context/admin";

const TableCell = styled("td")`
  border-bottom: 1px solid #cececef2;
  padding-bottom: 20px;
  padding-top: 20px;
`;
const OrderItems = ({ order }) => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const { priceFormat } = usePriceFormat({ location: order.location });
  const breakpoints = useContext(BreakpointContext);
  const { orderSettings } = useContext(SettingsContext);

  const groupedChoices = (choices) => {
    if (_.isEmpty(choices)) return;

    return _.groupBy(choices, (c) => {
      return c.modifier.name;
    });
  };

  useEffect(() => {
    if (_.isEmpty(order)) return;
    const timePass =
      new Date().getTime() - new Date(order.approvedAt).getTime();
    const timePassInMin = _.toInteger(timePass / 60000);
    console.log(timePassInMin);
    if (order.preparationTime)
      setTimeRemaining(order.preparationTime - timePassInMin);
  }, [order]);
  return (
    <>
      {!_.isEmpty(order) && (
        <>
          <Card>
            <CardHeader>
              <div className="row">
                <div className="col-8">Items: {order.lineitems.length}</div>
                {order.state === "APPROVED" && (
                  <>
                    <div className="col-4 text-end ">
                      Time Remaining:{" "}
                      <Button
                        status="Primary"
                        appearance="outline"
                        pulse={true}
                        shape="Round"
                        onClick={() => {}}
                      >
                        {timeRemaining} M
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </CardHeader>

            <CardBody>
              <table className="w-100">
                <tbody>
                  {order.lineitems.map((lineitem, index) => (
                    <tr key={index}>
                      {orderSettings.orderDetails.items.showImage && (
                        <TableCell style={{ width: 100 }}>
                          <div className="row p-1">
                            <div className="col-12">
                              <StrapiImage
                                width={breakpoints.md ? 55 : 80}
                                size={STRAPI_IMAGE_SIZES.THUMBNAIL}
                                className="float-left"
                                src={lineitem.item?.photo?.url || ""}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )}

                      <TableCell>
                        <div className="row mx-3">
                          <div
                            className="col-12 text-center"
                            style={{ maxWidth: 125 }}
                          >
                            <div
                              className="row "
                              style={{ border: "1px solid gray" }}
                            >
                              <div className="col-6 py-2 pr-1 pl-3 text-right h5">
                                {lineitem.quantity}
                              </div>
                              <div className="col-6 py-2 pl-1 pr-3 text-left h5">
                                X
                              </div>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="align-middle">
                        <div className="row px-2">
                          {" "}
                          <div className="col-12">
                            <div className="row ml-2 ">
                              <div className="col-12">{lineitem.title}</div>
                            </div>
                            {!_.isEmpty(lineitem.choices) && (
                              <>
                                <div className="row ml-2 mt-3 py-2 ">
                                  <div className="col-12">
                                    {_.map(
                                      groupedChoices(lineitem.choices),
                                      (v, k) => {
                                        return (
                                          <React.Fragment key={k}>
                                            <b>{_.capitalize(k)}</b>:
                                            <ul className="mt-1">
                                              {v.map((c, i) => {
                                                return (
                                                  <React.Fragment key={i}>
                                                    <li>
                                                      {_.startCase(c.title)}
                                                    </li>
                                                  </React.Fragment>
                                                );
                                              })}
                                            </ul>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </>
                            )}

                            {!_.isEmpty(lineitem.note) && (
                              <>
                                <div className="row mx-2 mt-4 py-2">
                                  <div className="col-12 h6">NOTE:</div>

                                  <div className="col-12"> {lineitem.note}</div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </TableCell>
                      {orderSettings.orderDetails.items
                        .showUnitPriceForEachItem && (
                        <TableCell className="align-middle text-end">
                          <div className="row ml-2 p-3">
                            <div className="col-12">
                              {priceFormat(lineitem?.unitPrice)} <b>x</b>{" "}
                              {lineitem.quantity} <b>=</b>{" "}
                              {priceFormat(lineitem?.totalPrice)}
                            </div>
                          </div>
                        </TableCell>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
            <CardFooter>
              <OrderSummary order={order} />
            </CardFooter>
          </Card>
        </>
      )}
    </>
  );
};

export default OrderItems;
