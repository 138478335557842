import React from "react";
import { SettingsContextProvider, SettingsContext } from "./SettingsContext";
import { GlobalContextProvider, GlobalContext } from "./globalContext";

const ContextProviders = ({ children }) => {
  return (
    <GlobalContextProvider>
      <SettingsContextProvider>{children}</SettingsContextProvider>
    </GlobalContextProvider>
  );
};
export default ContextProviders;
export { SettingsContext, GlobalContext };
