import React, { useMemo } from "react";
import _ from "lodash";

import { Card, CardBody, CardHeader } from "@paljs/ui/Card";

const CustomerInfo = ({ order }) => {
  const phone = useMemo(() => {
    return (
      order.phone ||
      order.shippingAddress?.phone ||
      order.billingAddress?.phone ||
      ""
    );
  }, [order]);
  return (
    <>
      {!_.isEmpty(order) && (
        <>
          <Card>
            <CardHeader>Customer</CardHeader>
            <CardBody>
              <div className="row py-1">
                <div className="col text-capitalize">{`${
                  order.shippingAddress?.firstName ||
                  order.billingAddress?.firstName ||
                  ""
                } 
                ${
                  order.shippingAddress?.lastName ||
                  order.billingAddress?.lastName ||
                  ""
                }`}</div>
              </div>
              <div className="row py-1">
                <div className="col-12">
                  <a href={`mailto:${order.email}`}>{order.email}</a>
                </div>
              </div>
              <div className="row py-1">
                <div className="col-12">
                  <a href={`tel:${phone}`}>{phone}</a>
                </div>
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
};

export default CustomerInfo;
