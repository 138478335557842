import React, { useContext } from "react";
import { Button } from "@paljs/ui/Button";
import { STATES_MAP } from "../../data";
import { BreakpointContext } from "../../../../context";

const ApproveStateButton = ({ setIsOpen, order }) => {
  const breakpoints = useContext(BreakpointContext);
  return (
    <>
      <Button
        status={STATES_MAP.APPROVED.color}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {STATES_MAP.APPROVED.label}
      </Button>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 1,
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: 25,
            width: "100%",
            padding: breakpoints.lg ? "0 100px" : "0 150px",
          }}
        >
          <Button
            status={STATES_MAP.APPROVED.color}
            fullWidth
            pulse
            onClick={() => {
              setIsOpen(true);
            }}
          >
            {STATES_MAP.APPROVED.label}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ApproveStateButton;
