import React, { memo, useContext, useMemo, useCallback } from "react";
import { useTable } from "react-table";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import moment from "moment";

import { Tabs, Tab } from "@paljs/ui/Tabs";
import { Card } from "@paljs/ui/Card";
import Spinner from "@paljs/ui/Spinner";

// import useOrderDataMap from "./utils/useOrderDataMap";
// import useTable from "./utils/useTable";
// import { useAlert } from "react-alert";
// import { GlobalContext } from "../../context/admin";
import Seo from "../../components/SEO";
import { useOffers } from "../../queries";
import { GlobalContext } from "../../context/admin";
import { StyledTable } from "../../components/Table/StyledTable";
import useArchiveOffer from "../../mutations/useArchiveOffer";

import CreateOfferDialogButton, { OPTIONS } from "./CreateOfferDialogButton";
import { Button } from "@mui/material";

const Offers = ({ restaurant }) => {
  const { data, loading, refetch } = useOffers(restaurant.id, {
    archived: false,
  });
  const { data: archivedData, refetch: refetchExpired } = useOffers(
    restaurant.id,
    { archived: true }
  );

  const [expiredOffers, activeOffers] = useMemo(
    () =>
      _.partition(
        data?.offersByRestaurant?.map(formatOffer),
        (o) => o.expiresAt && moment(o.expiresAt).isBefore(moment())
      ) ?? [[], []],
    [data]
  );

  const archivedOffers = useMemo(
    () => [
      ...(archivedData?.offersByRestaurant?.map(formatOffer) ?? []),
      ...expiredOffers,
    ],
    [archivedData, expiredOffers]
  );

  const {
    archive,
    loading: archiving,
    called: archiveCalled,
  } = useArchiveOffer();

  const handleArchive = useCallback(async (id) => {
    try {
      await archive(id);

      await refetch();
      return refetchExpired();
    } catch (err) {
      alert(err.message);
    }
  }, []);

  return (
    <>
      <Seo title="Offers" />
      <Card>
        <Tabs>
          <Tab title="Active Offers">
            <OffersContainer>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <CreateOfferDialogButton onCreate={refetch} />
                  <Table
                    data={activeOffers}
                    onArchive={handleArchive}
                    archiving={archiveCalled && archiving}
                    showArchiveButton
                  />
                </>
              )}
            </OffersContainer>
          </Tab>
          <Tab title="Inactive Offers">
            <OffersContainer>
              <Table data={archivedOffers} />
            </OffersContainer>
          </Tab>
        </Tabs>
      </Card>
    </>
  );
};

function Table({ data, onArchive, archiving, showArchiveButton }) {
  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
        style: {
          fontWeight: "bold",
        },
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Amount",
        accessor: "discount",
      },
      {
        Header: "Expiry",
        accessor: "expiresAt",
        Cell: ({ value }) => {
          return value ? moment(value).calendar() : "";
        },
      },
      ...(showArchiveButton
        ? [
            {
              Header: "",
              accessor: "id",
              Cell: ({ value: id }) => {
                return (
                  <Button onClick={() => onArchive(id)} disabled={archiving}>
                    Archive
                  </Button>
                );
              },
            },
          ]
        : []),
    ],
    [showArchiveButton]
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps({
                      style: cell.column.style ?? {},
                    })}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
}

function formatOffer(offer) {
  return {
    ...offer,
    type: OPTIONS.find((o) => o.value === offer.type).label,
  };
}

const WithRestaurant = () => {
  const { restaurant } = useContext(GlobalContext);

  if (_.isEmpty(restaurant)) {
    return <Spinner size="giant" />;
  }

  return <Offers restaurant={restaurant} />;
};

const OffersContainer = styled("div")`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export default memo(WithRestaurant);
