import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@paljs/ui/Button";

import Label from "../../components/Label";
import Select from "@paljs/ui/Select";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import { SettingsContext } from "../../context/admin";
import { Card, CardBody, CardHeader } from "@paljs/ui/Card";

const GeneralSettings = () => {
  const {
    themeSettings,
    setThemeSettings,
    notificationSettings: notification,
  } = useContext(SettingsContext);

  const [buttonLoadingDisable, setButtonLoadingDisable] = useState(false);
  return (
    <>
      <Card style={{ minHeight: "280px" }}>
        <CardHeader>General Site Settings</CardHeader>
        <CardBody>
          <Row>
            <Col breakPoint={{ md: 6 }}>
              <Label>Desktop Notification</Label>
              <Button
                shape="Round"
                appearance="outline"
                fullWidth
                disabled={buttonLoadingDisable}
                onClick={async () => {
                  setButtonLoadingDisable(true);
                  notification.settings.subscribe
                    ? await notification.unSubscribe()
                    : await notification.subscribe();

                  setButtonLoadingDisable(false);
                }}
              >
                {notification.settings.subscribe ? <>Disable</> : <> Enable </>}
              </Button>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col breakPoint={{ md: 6 }}>
              <Label>Theme</Label>
              <SelectStyled
                isSearchable={false}
                placeholder="Themes"
                value={themeSettings.themeOptions.find(
                  (item) => item.value === themeSettings.theme
                )}
                options={themeSettings.themeOptions}
                onChange={({ value }) =>
                  setThemeSettings({ ...themeSettings, theme: value })
                }
              />
            </Col>
            <Col breakPoint={{ md: 6 }}>
              <Label>Site Dirction</Label>
              <Select
                isSearchable={false}
                placeholder="Dir"
                value={themeSettings.dirOptions.find(
                  (item) => item.value === themeSettings.dir
                )}
                options={themeSettings.dirOptions}
                onChange={({ value }) =>
                  setThemeSettings({ ...themeSettings, dir: value })
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

const SelectStyled = styled(Select)`
  min-width: 150px;
`;

export default GeneralSettings;
