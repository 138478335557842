import React, { useRef, useContext } from "react";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { ThemeProvider } from "styled-components";

import themes from "./themes";

import { SettingsContext } from "../../../context/admin";
import {
  Layout,
  LayoutContent,
  LayoutFooter,
  LayoutContainer,
  LayoutColumns,
  LayoutColumn,
} from "@paljs/ui/Layout";
import icons from "@paljs/icons";
import Header from "./Header";
import SimpleLayout from "./SimpleLayout";
import SidebarCustom from "./Sidebar";
import Audio from "../../Audio";
import BetterRestoLogo from "../../BetterRestoLogo";

const theme = createTheme();

const LayoutPage = ({ children, layout }) => {
  const { themeSettings } = useContext(SettingsContext);
  const sidebarRef = useRef(null);
  console.dir(themes(themeSettings.theme, themeSettings.dir));

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={themes(themeSettings.theme, themeSettings.dir)}>
          <>
            <Audio />
            <SimpleLayout />
            <Layout
              evaIcons={icons}
              dir={themeSettings.dir}
              className={layout === "auth" ? "auth-layout" : ""}
            >
              {layout !== "auth" && (
                <Header toggleSidebar={() => sidebarRef.current?.toggle()} />
              )}
              <LayoutContainer>
                {layout !== "auth" && <SidebarCustom ref={sidebarRef} />}
                <LayoutContent>
                  <LayoutColumns>
                    <LayoutColumn className="main-content">
                      {children}
                    </LayoutColumn>
                  </LayoutColumns>
                  {layout !== "auth" && (
                    <LayoutFooter>
                      <div style={{ fontSize: 12 }}>
                        COPYRIGHT © {new Date().getFullYear()}{" "}
                        <BetterRestoLogo fontSize={13} /> | ALL RIGHTS RESERVED
                      </div>
                    </LayoutFooter>
                  )}
                </LayoutContent>
              </LayoutContainer>
            </Layout>
          </>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export default LayoutPage;
