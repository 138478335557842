import React, { useMemo } from "react";
import _ from "lodash";
import { InputGroup } from "@paljs/ui/Input";
import { usePriceFormat } from "../../../hooks";

const OrderSummary = ({ order }) => {
  const { priceFormat } = usePriceFormat({ location: order.location });
  const taxesBreakDown = useMemo(() => {
    if (_.isEmpty(order.location.taxes)) return;

    return order.location.taxes.map((t) => {
      return {
        description: t.description,
        price: priceFormat((t.rate / 100) * order.subtotal),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.location]);
  return (
    <>
      {!_.isEmpty(order) && (
        <div className="container pb-3  px-4">
          <div className="row">
            <div className="col-md-6">
              <div className="row py-1">
                <div className="col-12 text-muted">NOTE:</div>
              </div>
              {order.note && (
                <>
                  <div className="row py-2">
                    <div className="col-12">
                      <InputGroup fullWidth>
                        <textarea value={order.note} readOnly />
                      </InputGroup>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-6">
              <div className="row pb-1">
                <div className="col-4 "></div>
                <div className="col-4 " style={{ fontWeight: 600 }}>
                  Subtotal:
                </div>
                <div className="col-4 text-end">
                  {priceFormat(order?.subtotal)}
                </div>
              </div>
              {order.discountTotal > 0 && (
                <div className="row pb-1">
                  <div className="col-4 "></div>
                  <div className="col-4 " style={{ fontWeight: 600 }}>
                    Discount:
                  </div>
                  <div className="col-4 text-end">
                    ({order?.offer?.code && order?.offer?.code}) -{" "}
                    {priceFormat(order?.discountTotal)}
                  </div>
                </div>
              )}
              {order.type === "delivery" && (
                <div className="row pb-1">
                  <div className="col-4 "></div>
                  <div className="col-4 " style={{ fontWeight: 600 }}>
                    Delivery Fee:
                  </div>
                  <div className="col-4 text-end">
                    {priceFormat(order?.deliveryFees)}
                  </div>
                </div>
              )}
              {order.taxTotal > 0 && (
                <>
                  <div className="py-3">
                    {taxesBreakDown.map((t, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className="row ">
                            <div className="col-4 "></div>
                            <div className="col-4 ">{t.description}</div>
                            <div className="col-4 text-end">{t.price}</div>
                          </div>
                        </React.Fragment>
                      );
                    })}

                    <div className="row pt-1">
                      <div className="col-4 "></div>
                      <div className="col-4 " style={{ fontWeight: 600 }}>
                        Total Tax:
                      </div>
                      <div className="col-4 text-end">
                        {priceFormat(order?.taxTotal)}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {order.tip > 0 && (
                <div className="row pb-1">
                  <div className="col-4 "></div>
                  <div className="col-4 " style={{ fontWeight: 600 }}>
                    Tip :
                  </div>
                  <div className="col-4 text-end">
                    {priceFormat(order?.tip)}
                  </div>
                </div>
              )}
              <div className="row py-1 text-right">
                <div className="col-4 "></div>

                <div className="col-4 " style={{ fontWeight: 600 }}>
                  <hr />
                  Total:
                </div>
                <div className="col-4 text-end">
                  <hr />
                  {priceFormat(order?.total)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderSummary;
