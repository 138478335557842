import React, { useContext } from "react";
import _ from "lodash";
import { Button } from "@paljs/ui/Button";
import { GlobalContext } from "../../context/admin";

const NotificationDismiss = () => {
  const { newOrders, setNewOrders } = useContext(GlobalContext);

  const onClickDismiss = () => {
    setNewOrders([]);
  };
  return (
    <>
      {!_.isEmpty(newOrders) && (
        <div>
          <Button
            status="Warning"
            pulse={true}
            appearance="hero"
            shape="Round"
            onClick={onClickDismiss}
          >
            NEW ORDER CONFIRM
          </Button>
        </div>
      )}
    </>
  );
};

export default NotificationDismiss;
