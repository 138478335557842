import React, { useContext } from "react";
import { Checkbox } from "@paljs/ui/Checkbox";

import Label from "../../components/Label";
import _ from "lodash";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import { SettingsContext } from "../../context/admin";
import { Card, CardBody, CardHeader } from "@paljs/ui/Card";
import produce from "immer";

const OrderDetailsSettings = () => {
  const { orderSettings, setOrderSettings } = useContext(SettingsContext);

  return (
    <>
      <Card style={{ minHeight: "280px" }}>
        <CardHeader>Order Details Settings</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Label>Items Table Settings</Label>
              <Row>
                {_.map(orderSettings.orderDetails.items, (v, k) => {
                  return (
                    <React.Fragment key={k}>
                      <Col>
                        <Checkbox
                          status="Basic"
                          checked={v}
                          onChange={async (value) => {
                            console.log(value);
                            setOrderSettings(
                              produce(orderSettings, (draft) => {
                                draft.orderDetails.items[k] = value;
                              })
                            );
                          }}
                        >
                          {_.startCase(k)}
                        </Checkbox>
                      </Col>
                    </React.Fragment>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default OrderDetailsSettings;
