import _ from "lodash";

import STATES from "../../utils/orderStateMap";

const mapColorState = _.mapValues(STATES, "color");

const STATES_OPTIONS = _.map(STATES, (v, k) => {
  return {
    label: v.label,
    value: k,
  };
});

const mapProgressState = _.mapValues(STATES, (s) => {
  return {
    status: s.color,
    value: s.progressBarValue,
    label: s.label,
  };
});
const STATES_MAP = STATES;
export { STATES_MAP, mapColorState, mapProgressState, STATES_OPTIONS };
