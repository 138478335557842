import React, { useEffect, useState } from "react";
import _ from "lodash";
import STATES_MAP from "../../../utils/orderStateMap";
import { Button } from "@paljs/ui/Button";
import { usePriceFormat } from "../../../hooks";
const useOrderDataMap = ({ ordersData, loadingOrders }) => {
  const [mapOrdersData, setMapOrdersData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const { priceFormat } = usePriceFormat({});
  useEffect(() => {
    const formatData = (data, state) => {
      if (state === "CANCELLED") return <del>{data}</del>;

      return data;
    };
    if (_.isEmpty(ordersData) || loadingOrders) return;
    if (_.isEmpty(ordersData.ordersByRestaurant)) return;
    let data = [];

    ordersData.ordersByRestaurant.values.forEach((v) => {
      if (_.isEmpty(v)) return;

      data.push({
        number: v.number,
        displayOrderId: formatData(v.displayOrderId, v.state),
        created_at: v.created_at,
        location: v.location?.name || "",
        customer: formatData(
          _.capitalize(
            `${
              v.shippingAddress?.firstName || v.billingAddress?.firstName || ""
            } ${
              v.shippingAddress?.lastName || v.billingAddress?.lastName || ""
            }`
          ),
          v.state
        ),
        state: (
          <Button
            pulse={v.state === "PENDING" && !v.archive}
            appearance="ghost"
            shape="Round"
            status={STATES_MAP[v.state].color}
          >
            {formatData(STATES_MAP[v.state].label, v.state)}
          </Button>
        ),
        items: formatData(v.lineitems.length, v.state),
        total: priceFormat(v.total, v.location?.currency),
        type: formatData(_.capitalize(v.type), v.state),
        archived: v.archive,
      });
    });

    setMapOrdersData(data);

    const total = ordersData.ordersByRestaurant.totalCount;
    setTotalOrders(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersData]);

  return { mapOrdersData, totalOrders };
};

export default useOrderDataMap;
