import { gql, useMutation } from "@apollo/client";

const useNotification = () => {
  const CREATE_NOTIFICATION = gql`
    mutation CreateNotification(
      $token: String!
      $user: ID!
      $deviceInfo: String
    ) {
      createNotification(
        input: { data: { token: $token, user: $user, deviceInfo: $deviceInfo } }
      ) {
        notification {
          id
        }
      }
    }
  `;
  const DELETE_NOTIFICATION = gql`
    mutation DeleteNotification($id: ID!) {
      deleteNotification(input: { where: { id: $id } }) {
        notification {
          id
        }
      }
    }
  `;

  const [setData, { data }] = useMutation(CREATE_NOTIFICATION);
  const [setDeleteData, { error: deleteError }] = useMutation(
    DELETE_NOTIFICATION
  );

  return [data, deleteError, setData, setDeleteData];
};

export default useNotification;
