import React, { useContext, useRef, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { usePagination, useSortBy, useTable } from "react-table";
import { breakpointDown } from "@paljs/ui/breakpoints";
import { Card, CardBody } from "@paljs/ui/Card";
import { Button } from "@paljs/ui/Button";
import { EvaIcon } from "@paljs/ui/Icon";
// import { InputGroup } from "@paljs/ui/Input";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import { initPages } from "./utils";
import { SettingsContext } from "../../context/admin";
import Spinner from "@paljs/ui/Spinner";
import Tooltip from "@paljs/ui/Tooltip";
// import { Checkbox } from "@paljs/ui/Checkbox";
// import { ListConnect } from "./ListConnect";
import Select from "@paljs/ui/Select";
import classNames from "classnames";
import _ from "lodash";
import { StyledTable } from "./StyledTable";

const Table = ({
  //   initialFilter,
  tableName,
  columnList,
  data,
  loading,
  pageCount: controlledPageCount,
  sortByHandler,
  //   filterHandler,
  paginationHandler,
  onRowClicked,
  hiddenColumns,
  //   inEdit,
  //   connect,
  //   parent,
  actionsHeader,
}) => {
  const {
    //     schema: { models },
    //     push,
    //     pagesPath,
    tableSettings: { pageSizeOptions, paginationOptions, pageSizeDefault },
    //     onSelect,
  } = useContext(SettingsContext);
  const tableInstance = useTable(
    {
      columns: columnList,
      data,
      initialState: { pageSize: pageSizeDefault }, // Pass our hoisted table state
      //   manualFilters: true,
      manualSortBy: true,
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    // useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    // setAllFilters,
    state: { pageIndex, pageSize, filters, sortBy },
  } = tableInstance;

  const tableRef = useRef(null);
  const [columnSize, setColumnSize] = useState(1);
  // const [selected, setSelected] = useState([]);
  // Listen for changes in pagination and use the state to fetch our new data

  //   const onSelectHandler = (state, id) => {
  //     let newValues;
  //     if (!state && !id) {
  //       newValues = [];
  //       setSelected(newValues);
  //     } else if (state && !id && model) {
  //       newValues = data.map((row) => row[model.idField]);
  //       setSelected(newValues);
  //     } else if (!state && id) {
  //       newValues = selected.filter((value) => value !== id);
  //       setSelected(newValues);
  //     } else {
  //       newValues = [...selected, id];
  //       setSelected(newValues);
  //     }
  //     onSelect && onSelect(newValues);
  //   };

  useEffect(() => {
    if (!_.isFunction(paginationHandler)) return;

    paginationHandler(pageSize, pageIndex);
  }, [pageIndex, pageSize, paginationHandler]);

  useEffect(() => {
    if (!_.isFunction(sortByHandler)) return;
    sortByHandler(sortBy);
  }, [sortBy, sortByHandler]);

  useEffect(() => {
    // filterHandler(filters);
    console.log(filters);
  }, [filters]);

  useEffect(() => {
    if (_.isEmpty(hiddenColumns) || !_.isArray(hiddenColumns)) return;

    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns]);

  useEffect(() => {
    function columnHandler() {
      const clientRect = tableRef?.current?.getBoundingClientRect();
      if (clientRect) {
        setColumnSize(clientRect.width / columnList.length);
      }
    }

    if (columnList.length > 0) columnHandler();
    window.addEventListener("resize", columnHandler);
    return () => {
      window.removeEventListener("resize", columnHandler);
    };
  }, [columnList]);

  //   const actions = {
  //     create: model.create,
  //     update: model.update,
  //     delete: model.delete,
  //   };

  //   const isSelect = onSelect && !inEdit;

  //   const hasFilters = filters.length > 0;

  //   const parentModel = models.find((item) => item.id === parent?.name);
  //   const fieldUpdate = parentModel?.fields.find((f) => f.name === parent.field)
  //     .update;
  // Render the UI for your table
  return (
    <Card style={{ marginBottom: 0, maxHeight: "100vh" }}>
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {tableName}
        {actionsHeader}
      </header>
      <CardBody id="popoverScroll">
        {loading && <Spinner size="Giant" />}
        <StyledTable
          ref={tableRef}
          {...getTableProps()}
          columnSize={columnSize}
          footerRow
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <React.Fragment key={index}>
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {/* {isSelect && <th>Select</th>} */}
                  {/* <th colSpan={2}>Actions</th> */}
                  {/* {fieldUpdate && parent && <th>Relation</th>} */}
                  {headerGroup.headers.map((column, index2) => (
                    <th
                      key={index2}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
                {/* <tr>
                  {isSelect && (
                    <th>
                      <Checkbox
                        onChange={onSelectHandler}
                        checked={
                          data.length > 0 && selected.length === data.length
                        }
                        indeterminate={
                          selected.length > 0 && selected.length !== data.length
                        }
                      />
                    </th>
                  )}
                  {connect ? (
                    <th colSpan={2} />
                  ) : (
                    <th colSpan={2}>
                      {actions.create && (
                        <Button size="Tiny" onClick={() => onAction("create")}>
                          <EvaIcon name="plus-outline" />
                        </Button>
                      )}
                    </th>
                  )}
                  {fieldUpdate && parent && (
                    <th>
                      <Button
                        size="Small"
                        shape="SemiRound"
                        onClick={() => {
                          if (hasFilters) {
                            setAllFilters([]);
                          } else {
                            setAllFilters(initialFilter);
                          }
                        }}
                      >
                        {hasFilters ? "View All" : "View Related"}
                      </Button>
                    </th>
                  )}
                  {headerGroup.headers.map((column, index) => (
                    <th key={index}>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </th>
                  ))}
                </tr> */}
              </React.Fragment>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  key={index}
                  {...row.getRowProps()}
                  className={classNames({
                    "text-muted": row?.original?.archived,
                  })}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onRowClicked(row);
                  }}
                >
                  {/* {isSelect && (
                    <td>
                      <Checkbox
                        onChange={(value) =>
                          onSelectHandler(
                            value,
                            model && row.original[model.idField]
                          )
                        }
                        checked={
                          !!(
                            model &&
                            selected.includes(row.original[model.idField])
                          )
                        }
                      />
                    </td>
                  )} */}
                  {/* {connect && (
                    <td colSpan={2}>
                      <Button
                        size="Small"
                        appearance="ghost"
                        status="Success"
                        disabled={
                          model &&
                          connect[model.idField] === row.original[model.idField]
                        }
                        onClick={() =>
                          onAction(
                            "connect",
                            data.find(
                              (item) =>
                                model &&
                                item[model.idField] ===
                                  row.original[model.idField]
                            )
                          )
                        }
                      >
                        {model &&
                        connect[model.idField] === row.original[model.idField]
                          ? "Connected"
                          : "Connect"}
                      </Button>
                    </td>
                  )} */}
                  {/* {!connect && (
                    <td colSpan={actions.delete ? 1 : 2}>
                      <Tooltip
                        className="inline-block"
                        status="Primary"
                        trigger="hint"
                        placement="top"
                        content={actions.update ? "Edit Row" : "View Row"}
                      >
                        <Button
                          style={{ padding: 0 }}
                          appearance="ghost"
                          onClick={() =>
                            model &&
                            push(
                              `${pagesPath}${modelName}?${
                                actions.update ? "update" : "view"
                              }=${row.original[model.idField]}`
                            )
                          }
                        >
                          <EvaIcon
                            name={
                              actions.update ? "edit-outline" : "eye-outline"
                            }
                          />
                        </Button>
                      </Tooltip>
                    </td>
                  )} */}
                  {/* {actions.delete && !connect && (
                    <td colSpan={1}>
                      <Tooltip
                        className="inline-block"
                        status="Danger"
                        trigger="hint"
                        placement="top"
                        content="Delete Row"
                      >
                        <Button
                          style={{ padding: 0 }}
                          status="Danger"
                          appearance="ghost"
                          onClick={() => {
                            const confirm = window.confirm(
                              "Are you sure you want to delete this record ?"
                            );
                            if (confirm && model)
                              onAction("delete", row.original[model.idField]);
                          }}
                        >
                          <EvaIcon name="trash-2-outline" />
                        </Button>
                      </Tooltip>
                    </td>
                  )} */}
                  {/* {parent && model && fieldUpdate && (
                    <ListConnect parent={parent} row={row} model={model} />
                  )} */}
                  {row.cells.map((cell, index2) => {
                    return (
                      <td key={index2} {...cell.getCellProps()}>
                        {cell.value &&
                          // <Popover
                          //   eventListener="#popoverScroll"
                          //   trigger="hint"
                          //   placement="top"
                          //   overlay={
                          //     <Card
                          //       style={{
                          //         marginBottom: "0",
                          //         maxHeight: "300px",
                          //       }}
                          //     >
                          //       <CardBody>
                          //         <div style={{ maxWidth: "300px" }}>
                          //           {cell.render("Cell")}
                          //         </div>
                          //       </CardBody>
                          //     </Card>
                          //   }
                          // >
                          //   <div style={{ cursor: "pointer" }}>
                          //     {cell.render("Cell")}
                          //   </div>
                          // </Popover>

                          cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr>
              {/* <td colSpan={10000}>
                Showing {page.length} of ~{controlledPageCount} results
              </td> */}
            </tr>
          </tbody>
        </StyledTable>
      </CardBody>
      {pageCount > 1 && (
        <footer>
          <StyledRow middle="xs">
            <Col breakPoint={{ md: 4 }}></Col>
            <Col breakPoint={{ md: 4 }}></Col>
            <Col breakPoint={{ md: 4 }}>
              <Row>
                <Col breakPoint={{ md: 3 }}>
                  <Select
                    options={pageSizeOptions}
                    placeholder={pageSize}
                    onChange={(v) => {
                      setPageSize(v.value);
                    }}
                    isDisabled={page.length < pageSizeDefault}
                  />
                </Col>
                <Col breakPoint={{ md: 9 }}>
                  <Tooltip
                    className="inline-block"
                    status="Primary"
                    trigger="hint"
                    placement="top"
                    content="Go to first page"
                  >
                    <StyledButton
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      <EvaIcon name="arrowhead-left-outline" />
                    </StyledButton>
                  </Tooltip>
                  <StyledButton
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <EvaIcon name="arrow-ios-back" />
                  </StyledButton>
                  {initPages(pageCount, pageIndex + 1, paginationOptions).map(
                    (item) => (
                      <StyledButton
                        key={item}
                        onClick={() => gotoPage(item - 1)}
                        status={item === pageIndex + 1 ? "Primary" : "Basic"}
                      >
                        {item}
                      </StyledButton>
                    )
                  )}
                  <StyledButton
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    <EvaIcon name="arrow-ios-forward" />
                  </StyledButton>
                  <Tooltip
                    className="inline-block"
                    status="Primary"
                    trigger="hint"
                    placement="top"
                    content="Go to last page"
                  >
                    <StyledButton
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      <EvaIcon name="arrowhead-right-outline" />
                    </StyledButton>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </StyledRow>
        </footer>
      )}
    </Card>
  );
};

const StyledButton = styled(Button)`
  margin-right: 5px;
  padding: 0.3rem;
`;

const StyledRow = styled(Row)`
  text-align: center;
  ${breakpointDown("md")`
    & > :not(:last-child) {
      margin-bottom: 20px;
    }
  `}
`;

export default Table;
