import React, { useState, useEffect, useContext } from "react";
import { navigate } from "gatsby";
import Table from "../../../components/Table";
import _ from "lodash";
import produce from "immer";
import useTableHeader from "./useTableHeader";
import { SettingsContext, GlobalContext } from "../../../context/admin";
import { BreakpointContext, EmployeeContext } from "../../../context";

const useTable = ({ mapOrdersData, totalOrders, loadingOrders }) => {
  const {
    tableSettings: { pageSizeDefault },
  } = useContext(SettingsContext);
  const { restaurant } = useContext(GlobalContext);
  const { employee } = useContext(EmployeeContext);
  const breakpoints = useContext(BreakpointContext);
  const columnList = useTableHeader();
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [orderQueryVariables, setOrderQueryVariables] = useState({});
  const [pageSize, setPageSize] = useState(pageSizeDefault);
  const [pageCount, setPageCount] = useState(0);

  const onRowClicked = (row) => {
    console.log(row);
    navigate(`/${process.env.GATSBY_ADMIN_URL}/orders/${row.original.number}`);
  };

  useEffect(() => {
    if (
      _.isEmpty(restaurant) ||
      _.isUndefined(restaurant) ||
      !_.isObject(restaurant)
    )
      return;
    if (_.isEmpty(employee) || _.isUndefined(employee) || !_.isObject(employee))
      return;

    setOrderQueryVariables({
      sort: "created_at:desc",
      start: 0,
      limit: pageSize,
      restaurantId: restaurant.id,
      locationIds: employee.locations?.map((l) => l.id) || [],
      where: { archive: false },
    });

    if (employee.locations?.length === 1)
      setHiddenColumns((p) => [...p, "location"]);
    // hide columns in small screens

    if (breakpoints.md) {
      setHiddenColumns((p) => [...p, "location", "items", "total", "customer"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant, employee]);

  const onFilter = (obj) => {
    if (_.isEmpty(obj)) return;
    if (_.isEmpty(orderQueryVariables)) return;

    setOrderQueryVariables(
      produce(orderQueryVariables, (draft) => {
        draft.where = obj;
      })
    );
  };

  const sortByHandler = (handle) => {
    if (_.isEmpty(handle)) return;
    handle = handle[0];
    const maps = (handle) => {
      let handleId = handle.id;
      if (_.isEqual(handleId, "displayOrderId")) handleId = "number";

      return `${handleId}:${handle.desc ? "desc" : "asc"}`;
    };

    setOrderQueryVariables(
      produce(orderQueryVariables, (draft) => {
        draft.sort = maps(handle);
      })
    );
  };

  const fetchMore = (_pageSize, pageIndex) => {
    if (
      _.isUndefined(_pageSize) ||
      _.isUndefined(pageIndex) ||
      _.isEmpty(orderQueryVariables)
    )
      return;

    setOrderQueryVariables(
      produce(orderQueryVariables, (draft) => {
        draft.start = pageIndex * _pageSize;
        draft.limit = _pageSize;
      })
    );
    setPageSize(_pageSize);
  };

  useEffect(() => {
    if (pageSize === 0 || totalOrders === 0) return;

    setPageCount(_.ceil(totalOrders / pageSize));
  }, [pageSize, totalOrders]);

  const tableElement = (
    <Table
      tableName={`${totalOrders} Orders`}
      columnList={columnList}
      data={mapOrdersData}
      loading={loadingOrders}
      sortByHandler={sortByHandler}
      paginationHandler={fetchMore}
      pageCount={pageCount}
      onRowClicked={onRowClicked}
      hiddenColumns={hiddenColumns}
    />
  );

  return { tableElement, orderQueryVariables, pageSize, onFilter };
};

export default useTable;
