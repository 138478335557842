import { gql, useMutation } from "@apollo/client";

const MUTATE = gql`
  mutation ($input: createOfferInput!) {
    createOffer(input: $input) {
      __typename
    }
  }
`;

const useCreateOffer = () => {
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useCreateOffer;
