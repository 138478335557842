import React, { useContext } from "react";
import { navigate } from "gatsby";
import { UserAuthContext } from "../../context";
import Spinner from "@paljs/ui/Spinner";
import Layout from "../Layouts/admin";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const redirect = location.pathname;

  const { isAuthenticated, isLoading } = useContext(UserAuthContext);

  if (isLoading) return <Spinner status="Primary" />;
  if (!isAuthenticated) {
    navigate("/login", { state: { redirect } });
    return <></>;
  }
  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
};
export default PrivateRoute;
