import React from "react";
import _ from "lodash";

import { Card, CardBody, CardHeader } from "@paljs/ui/Card";

const ShippingAddress = ({ order }) => {
  return (
    <>
      {!_.isEmpty(order) && order.shippingAddress && order.type === "delivery" && (
        <>
          <Card>
            <CardHeader>Shipping Address</CardHeader>
            <CardBody>
              <div className="row py-1">
                <div className="col-12 text-capitalize">
                  {`${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`}
                </div>
              </div>
              <div className="row py-1">
                <div className="col-12">
                  {`${order.shippingAddress.address_1}`}
                </div>
              </div>
              {order.shippingAddress.address_2 && (
                <div className="row py-1">
                  <div className="col-12">
                    {`${order.shippingAddress.address_2}`}
                  </div>
                </div>
              )}

              <div className="row py-1">
                <div className="col-12">
                  {`${order.shippingAddress.city}, ${order.shippingAddress.province}`}
                </div>
              </div>
              <div className="row py-1">
                <div className="col-12">
                  {`${order.shippingAddress.postalCode} ${order.shippingAddress.country} `}
                </div>
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
};

export default ShippingAddress;
