import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
} from "@mui/material";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { Button } from "@paljs/ui/Button";
import Container from "@paljs/ui/Container";
import { Radio } from "@paljs/ui/Radio";
import { InputGroup } from "@paljs/ui/Input";
import { EvaIcon, Spinner } from "@paljs/ui";

import useCreateOffer from "../../mutations/useCreateOffer";
import { GlobalContext } from "../../context/admin";

export const OPTIONS = [
  { label: "Percent off", value: "percentage" },
  { label: "Fixed amount", value: "fixed_amount" },
  // "free_delivery"
  // "buy_x_get_y"
];

const schema = yup.object().shape({
  type: yup.mixed().oneOf(["percentage", "fixed_amount"]).required(),
  code: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z0-9]+$/, "Letters and numbers only")
    .required("Please enter a code"),
  discount: yup
    .number()
    .positive("Amount must be > 0")
    .required()
    .when("type", {
      is: "percentage",
      then: (schema) =>
        schema.max(100, "Discount must be less than 100 percent"),
    }),
  expiresAt: yup.date().nullable(),
});

function CreateOfferDialogButton({ onCreate }) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const { restaurant } = useContext(GlobalContext);

  const { loading, called, setData: createOffer } = useCreateOffer();

  const create = async (v) => {
    try {
      setError(null);

      await createOffer({
        variables: { input: { data: { ...v, restaurant: restaurant.id } } },
      });

      onCreate();

      setOpen(false);
    } catch (err) {
      const { message } = err;
      setError(message ?? "Something went wrong creating offer");
    }
  };

  return (
    <>
      <Button status="Primary" onClick={() => setOpen(true)}>
        Create Offer
      </Button>

      <Dialog
        onClose={() => {
          setError(null);
          setOpen(false);
        }}
        aria-labelledby="simple-dialog-create-offer"
        open={open}
        fullWidth={true}
      >
        <Formik
          initialValues={{ expiresAt: null }}
          validationSchema={schema}
          onSubmit={(values) => {
            console.dir(values);
            create({ ...values, code: values.code.toUpperCase() });
          }}
          validateOnBlur
        >
          {({ values, submitForm }) => (
            <>
              <DialogTitle
                id="simple-dialog-create-offer"
                className="text-center"
              >
                Create offer
              </DialogTitle>

              <DialogContent>
                <Container>
                  <Form>
                    <InputGroup fullWidth>
                      <label>
                        <span className="mb-2">Code</span>
                        <Field type="text" name="code" />
                        <ErrorMessage component={Error} name="code" />
                      </label>
                    </InputGroup>
                    <Field name="type">
                      {({
                        field: { value, ...field },
                        form: { setFieldValue },
                      }) => (
                        <Radio
                          {...field}
                          onChange={(v) => setFieldValue("type", v)}
                          value={value ?? "percentage"}
                          options={OPTIONS.map((o) => ({
                            ...o,
                            checked: value === o.value,
                          }))}
                        />
                      )}
                    </Field>
                    <Hideable className={values.type ? "" : "hideable--hidden"}>
                      <label htmlFor="discount" className="mb-2">
                        Amount *
                      </label>
                      <InputGroup fullWidth>
                        <div>
                          <Aligned>
                            {values.type === "fixed_amount" ? "$" : null}
                            <Field
                              id="discount"
                              name="discount"
                              type="number"
                            />
                            {values.type === "percentage" ? "%" : null}
                          </Aligned>
                          <ErrorMessage component={Error} name="discount" />
                        </div>
                      </InputGroup>
                      <div className="mt-2">
                        <Field name="expiresAt">
                          {({
                            field: { value, ...field },
                            form: { setFieldValue },
                          }) => (
                            <Aligned>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                  {...field}
                                  renderInput={(props) => (
                                    <TextField {...props} />
                                  )}
                                  onChange={(moment) => {
                                    if (!moment) {
                                      setFieldValue("expiresAt", null);
                                    }

                                    setFieldValue("expiresAt", moment.format());
                                  }}
                                  value={value}
                                  label="Expires at"
                                  clearable
                                />
                              </LocalizationProvider>
                              <IconButton
                                aria-label="clear"
                                onClick={() => setFieldValue("expiresAt", null)}
                              >
                                <EvaIcon name="close" />
                              </IconButton>
                            </Aligned>
                          )}
                        </Field>
                        <ErrorMessage component={Error} name="expiresAt" />
                        {/* <MuiPickersUtilsProvider utils={moment}>
                            <Field
                              id="expriesAt"
                              name="expiresAt"
                              component={DateTimePicker}
                            />
                          </MuiPickersUtilsProvider> */}
                      </div>
                    </Hideable>
                  </Form>
                </Container>
              </DialogContent>
              <DialogActions>
                <Button status="Basic" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button
                  status="Primary"
                  onClick={() => submitForm()}
                  disabled={called && loading}
                >
                  <span>Create</span>
                  {called && loading ? <Spinner /> : null}
                </Button>
              </DialogActions>
              {error ? <DialogError>{error}</DialogError> : null}
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

const Aligned = styled("div")`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & > * {
    margin-right: 0.25rem;
  }
`;

const Hideable = styled("div")`
  transition: opacity 200ms ease-in;
  opacity: 1;

  &.hideable--hidden {
    opacity: 0;
  }
`;

const Error = styled("div")`
  font-size: 0.875rem;
  color: #ee3333;
`;

const DialogError = styled(Error)`
  padding: 1rem;
  text-align: right;
`;

export default CreateOfferDialogButton;
