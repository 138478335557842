import React, { useState } from "react";
import DefaultTable from "./tableSettings";
import useOrderSettings from "./useOrderSettings";
import useThemeSettings from "./useThemeSettings";
import useNotification from "./useNotification";

const SettingsContext = React.createContext({});

const SettingsContextProvider = ({ children }) => {
  const [tableSettings, setTableSettings] = useState(DefaultTable);
  const [orderSettings, setOrderSettings] = useOrderSettings();
  const [themeSettings, setThemeSettings] = useThemeSettings();
  const notificationSettings = useNotification();

  return (
    <SettingsContext.Provider
      value={{
        tableSettings,
        setTableSettings,
        orderSettings,
        setOrderSettings,
        themeSettings,
        setThemeSettings,
        notificationSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
export { SettingsContextProvider, SettingsContext };
