import React, { useMemo, useState, useContext, useEffect } from "react";
import Seo from "../../../components/SEO";
import Table from "../../../components/Table";
import { Button } from "@paljs/ui/Button";
import { axiosPost } from "../../../utils/axiosCall";
import Spinner from "@paljs/ui/Spinner";
import { GlobalContext, SettingsContext } from "../../../context/admin";
import { useRestaurantMedia } from "../../../queries";
import _ from "lodash";
import produce from "immer";
import { InputGroup } from "@paljs/ui/Input";
import { useCallback } from "react";
import { navigate } from "gatsby";
import { EvaIcon } from "@paljs/ui/Icon";
import { useDeleteMedia } from "../../../mutations";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@paljs/ui/Tooltip";

const Files = () => {
  const columnList = useMemo(() => {
    return [
      {
        accessor: "image",
        Header: "",
        disableSortBy: true,
      },
      {
        accessor: "name",
        Header: "File",
        disableSortBy: true,
      },
      {
        accessor: "url",
        Header: "URL",
        disableSortBy: true,
      },
      {
        accessor: "size",
        Header: "Size",

        disableSortBy: true,
      },
      {
        accessor: "deleteAction",
        Header: "",
        disableSortBy: true,
      },
    ];
  }, []);
  const [pageCount, setPageCount] = useState(0);
  const [disable, setDisable] = useState(false);
  const { restaurant } = useContext(GlobalContext);
  const {
    tableSettings: { pageSizeDefault },
  } = useContext(SettingsContext);
  const [pageSize, setPageSize] = useState(pageSizeDefault);

  const [returnData, loadingQuery, queryError, executeQuery] =
    useRestaurantMedia();
  const [queryVariables, setQueryVariables] = useState({});
  const [mapData, setMapData] = useState([]);
  const { data: deleteMediaReturnData, setData: setDeleteMedia } =
    useDeleteMedia();

  useEffect(() => {
    if (_.isEmpty(restaurant)) return;
    console.log(restaurant, "useEffect");

    setQueryVariables({
      id: restaurant.id,
      limit: pageSize,
      start: 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant]);

  useEffect(() => {
    if (_.isEmpty(queryVariables)) return;
    executeQuery(queryVariables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVariables]);

  useEffect(() => {
    if (_.isEmpty(deleteMediaReturnData)) return;
    navigate(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMediaReturnData]);

  useEffect(() => {
    if (_.isEmpty(returnData)) return;

    const mapped = returnData.restaurnatMedia.values.map((v) => {
      return {
        name: (
          <div className="row">
            <div className="col-12">{v.name}</div>
          </div>
        ),

        size: `${v.size} KB`,
        url: (
          <>
            <div className="row">
              <div className="col-12">
                <InputGroup fullWidth size="Small">
                  <input value={v.url} readOnly />
                  <Tooltip
                    eventListener="#scrollPlacementId"
                    trigger="hint"
                    placement="top"
                    content="Copy To Clipboard"
                  >
                    <CopyToClipboard text={v.url} onCopy={() => {}}>
                      <Button
                        appearance="ghost"
                        status="Basic"
                        size="Small"
                        onClick={() => {}}
                      >
                        <EvaIcon name="copy" />
                      </Button>
                    </CopyToClipboard>
                  </Tooltip>
                </InputGroup>
              </div>
            </div>
          </>
        ),
        image: (
          <img
            src={v.formats?.thumbnail?.url || v.url}
            width={80}
            alt=""
            // style={{ maxHeight: 80 }}
          />
        ),
        deleteAction: (
          <Button
            appearance="ghost"
            status="Basic"
            style={{ color: "black" }}
            onClick={() => {
              const res = window.confirm("Are you sure");
              console.log(res);
              if (res) {
                setDeleteMedia({
                  variables: { id: restaurant.id, mediaId: v.id },
                });
              }
            }}
          >
            <EvaIcon name="archive" />
          </Button>
        ),
      };
    });

    setMapData(mapped);
    setPageCount(_.ceil(returnData.restaurnatMedia.count / pageSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnData, queryError]);

  const onUploadFiles = useCallback(
    async ({ target: { files } }) => {
      console.log(restaurant, "onUploadFiles");
      if (_.isEmpty(restaurant)) return;

      setDisable(true);
      let formData = new FormData();

      Array.from(files).forEach((image) => {
        console.log(image);
        formData.append("files", image);
      });
      //   formData.append("refId", restaurant.id);
      //   formData.append("ref", "restaurants");
      //   formData.append("field", "media");
      formData.append("path", restaurant.id);

      const res = await axiosPost("upload", formData);
      console.log(res);
      setDisable(false);
      navigate(0);
    },
    [restaurant]
  );

  const fetchMore = (_pageSize, pageIndex) => {
    if (
      _.isUndefined(_pageSize) ||
      _.isUndefined(pageIndex) ||
      _.isEmpty(queryVariables)
    )
      return;
    console.log("fetchMore", _pageSize, pageIndex);
    setQueryVariables(
      produce(queryVariables, (draft) => {
        draft.start = pageIndex * _pageSize;
        draft.limit = _pageSize;
      })
    );
    setPageSize(_pageSize);
  };

  const actionsHeader = useMemo(() => {
    return (
      <>
        <Button status="Primary" size="Small" disabled={disable}>
          <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
            Upload Files
          </label>
          <input
            id="file-upload"
            type="file"
            name="files[]"
            multiple={true}
            onChange={onUploadFiles}
            style={{ display: "none", cursor: "pointer" }}
          />
        </Button>
      </>
    );
  }, [onUploadFiles, disable]);
  return (
    <>
      {disable && <Spinner>uploading...</Spinner>}
      <Seo title="Files" />
      <div className="row pb-2">
        <div className="col-12">
          <InputGroup fullWidth size="Large">
            <input
              type="text"
              placeholder="Search ..."
              onKeyDown={({ key, target: { value } }) => {
                if (key === "Enter") {
                  setQueryVariables({ ...queryVariables, search: value });
                }
              }}
            />
          </InputGroup>
        </div>
      </div>
      <div className="row pt-2">
        <div className="col-12">
          <Table
            tableName="Files"
            columnList={columnList}
            data={mapData}
            loading={loadingQuery}
            // sortByHandler={sortByHandler}
            paginationHandler={fetchMore}
            pageCount={pageCount}
            onRowClicked={() => {}}
            actionsHeader={actionsHeader}
          />
        </div>
      </div>
    </>
  );
};

export default Files;
