import { gql, useMutation } from "@apollo/client";

const useOrderSettings = () => {
  const MUTATE = gql`
    mutation UpdateOrderSettings(
      $prefixId: String!
      $suffixId: String!
      $startAt: Long!
      $restaurantId: ID!
    ) {
      updateRestaurant(
        input: {
          where: { id: $restaurantId }
          data: {
            settings: {
              orders: {
                prefixId: $prefixId
                suffixId: $suffixId
                startAt: $startAt
              }
            }
          }
        }
      ) {
        restaurant {
          id
        }
      }
    }
  `;

  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useOrderSettings;
