import React, { useContext, useMemo } from "react";
import { GlobalContext } from "../../../context/admin";
import _ from "lodash";
import { Button } from "@paljs/ui/Button";

const useMenuItem = () => {
  const { newOrders } = useContext(GlobalContext);

  const items = useMemo(() => {
    return [
      {
        title: "Home",
        icon: { name: "home", options: { animation: { type: "pulse" } } },
        link: { to: `/${process.env.GATSBY_ADMIN_URL}` },
      },
      {
        title: (
          <span>
            Orders{" "}
            {!_.isEmpty(newOrders) && (
              <Button
                size="Tiny"
                pulse={true}
                shape="Round"
                status="Warning"
                appearance="hero"
              >
                NEW
              </Button>
            )}
          </span>
        ),
        icon: {
          className: !_.isEmpty(newOrders) ? "text-warning" : "",
          status: "Basic",
          name: "arrow-downward",
          options: { animation: { type: "pulse" } },
        },
        link: { to: `/${process.env.GATSBY_ADMIN_URL}/orders` },
      },
      {
        title: "Offers",
        icon: {
          name: "percent-outline",
          options: { animation: { type: "pulse" } },
        },
        link: { to: `/${process.env.GATSBY_ADMIN_URL}/offers` },
      },
      {
        title: "Settings",
        group: true,
      },
      {
        title: "General",
        icon: { name: "settings-2", options: { animation: { type: "pulse" } } },
        link: { to: `/${process.env.GATSBY_ADMIN_URL}/settings` },
      },
      {
        title: "Files",
        icon: {
          name: "cloud-upload",
          options: { animation: { type: "pulse" } },
        },
        link: { to: `/${process.env.GATSBY_ADMIN_URL}/settings/files` },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOrders]);

  return items;
};

export default useMenuItem;
