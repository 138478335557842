import { gql, useMutation } from "@apollo/client";

const MUTATE = gql`
  mutation ($id: ID!) {
    archiveOffer(id: $id) {
      __typename
    }
  }
`;

const useArchiveOffer = () => {
  const [mutate, { data, loading, error }] = useMutation(MUTATE);

  const archive = (id) => mutate({ variables: { id } });

  return { data, loading, error, archive };
};

export default useArchiveOffer;
