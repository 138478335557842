import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useLocalStorage } from "../../../hooks";

import { EvaIcon } from "@paljs/ui/Icon";

const Label = styled("span")`
  display: flex;
  align-items: center;
`;

const defaultSettings = {
  theme: "default",
  dir: "ltr",
  dirOptions: [
    {
      value: "ltr",
      label: (
        <Label>
          <EvaIcon name="arrow-forward-outline" options={{ fill: "#3366ff" }} />
          <span className="px-1">LTR</span>
        </Label>
      ),
    },
    {
      value: "rtl",
      label: (
        <Label>
          <EvaIcon name="arrow-back-outline" options={{ fill: "#3366ff" }} />

          <span className="px-1">RTL</span>
        </Label>
      ),
    },
  ],
  themeOptions: [
    {
      value: "default",
      label: (
        <Label>
          <EvaIcon name="droplet" options={{ fill: "#a6c1ff" }} />
          Default
        </Label>
      ),
    },
    {
      value: "dark",
      label: (
        <Label>
          <EvaIcon name="droplet" options={{ fill: "#192038" }} />
          Dark
        </Label>
      ),
    },
  ],
};

const useSettings = () => {
  const [theme, setTheme] = useLocalStorage("theme", "default");
  const [dir, setDir] = useLocalStorage("direction", "ltr");

  const [settings, setSettings] = useState({
    ...defaultSettings,
    theme: theme,
    dir: dir,
  });

  useEffect(() => {
    setTheme(settings.theme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.theme]);
  useEffect(() => {
    setDir(settings.dir);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.dir]);

  return [settings, setSettings];
};

export default useSettings;
