import React from "react";

import Seo from "../../components/SEO";
import Container from "@paljs/ui/Container";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";

import OrderSettings from "./order";
import GeneralSettings from "./general";
import OrderDetailsSettings from "./orderDetails";

const Settings = () => {
  return (
    <>
      <Seo title="Settings" />
      <Container>
        <Row>
          <Col breakPoint={{ lg: 6 }}>
            <GeneralSettings />
          </Col>
          <Col breakPoint={{ lg: 6 }}>
            <OrderSettings />
          </Col>
          <Col breakPoint={{ lg: 6 }}>
            <OrderDetailsSettings />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
