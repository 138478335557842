import React, { useState } from "react";
import { Button } from "@paljs/ui/Button";
import Container from "@paljs/ui/Container";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Checkbox } from "@paljs/ui/Checkbox";

import { STATES_MAP } from "../../data";

const PickedUpState = ({ onSumbit }) => {
  const [disableButton, setDisableButton] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({
    notify: true,
  });

  const handleSumbit = () => {
    setDisableButton(true);
    setIsOpen(false);
    onSumbit(data, "PICKED_UP");
  };

  const handleClose = () => {
    setDisableButton(true);
    setIsOpen(false);
  };

  return (
    <>
      <Button
        status={STATES_MAP.PICKED_UP.color}
        onClick={() => {
          setIsOpen(true);
          setDisableButton(false);
        }}
      >
        {STATES_MAP.PICKED_UP.label}
      </Button>

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-ready-order"
        open={isOpen}
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-ready-order" className="text-center">
          {STATES_MAP.PICKED_UP.label} Order
        </DialogTitle>

        <Container>
          <Row className="my-2 pt-5">
            <Col className="col-12 ">
              <Checkbox
                name="notifyCustomer"
                status="Info"
                onChange={(value) => setData({ ...data, notify: value })}
                checked={data.notify}
              >
                <span className="text-muted">
                  Notify the customer of the order{" "}
                </span>
              </Checkbox>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="col-6">
              <Button appearance="ghost" status="Basic" onClick={handleClose}>
                Cancel
              </Button>
            </Col>

            <Col className="col-6 d-flex justify-content-end">
              <Button
                onClick={handleSumbit}
                disabled={disableButton}
                status={STATES_MAP.PICKED_UP.color}
              >
                Order is {STATES_MAP.PICKED_UP.label}
              </Button>
            </Col>
          </Row>
        </Container>
      </Dialog>
    </>
  );
};

export default PickedUpState;
