import React, { useEffect, useState } from "react";
import _ from "lodash";

import Progress from "@paljs/ui/ProgressBar";
import { mapProgressState } from "../data";

const OrderProgressBar = ({ order }) => {
  const [orderProgress, setOrderProgress] = useState({
    status: "Info",
    value: 0,
  });

  useEffect(() => {
    if (_.isEmpty(order)) return;

    setOrderProgress(mapProgressState[order.state]);
  }, [order]);

  return (
    <>
      {!_.isEmpty(order) && (
        <>
          <Progress
            style={{ border: "1px solid #cececef2", borderRadius: 5 }}
            value={orderProgress.value}
            size="Giant"
            status={orderProgress.status}
          >
            {_.capitalize(orderProgress.label)}
          </Progress>
          {order.state === "CANCELLED" && (
            <>
              <div className="text-center">
                Decline Reason: <b>{order.cancelReason}</b>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default OrderProgressBar;
