import React, { useEffect, useState } from "react";
import { Button } from "@paljs/ui/Button";
import Container from "@paljs/ui/Container";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import _ from "lodash";
import { Checkbox } from "@paljs/ui/Checkbox";
import { InputGroup } from "@paljs/ui/Input";

import { STATES_MAP } from "../../data";

const CancelState = ({ onSumbit }) => {
  const [disableButton, setDisableButton] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({
    cancelReason: -1,
    notify: true,
  });

  const handleSumbit = () => {
    setDisableButton(true);
    setIsOpen(false);
    onSumbit(data, "CANCELLED");
  };

  const handleClose = () => {
    setDisableButton(true);
    setIsOpen(false);
  };
  const onChange = ({ target: { value } }) => {
    setCancelReason(value);
    setDisableButton(false);
  };

  const [cancelReason, setCancelReason] = useState("");

  useEffect(() => {
    if (_.isEmpty(cancelReason)) return;
    setData((d) => {
      return { ...d, cancelReason };
    });
  }, [cancelReason]);

  return (
    <>
      <Button
        status={STATES_MAP.CANCELLED.color}
        appearance="ghost"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {STATES_MAP.CANCELLED.label}
      </Button>

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-cancel-order"
        open={isOpen}
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-cancel-order" className="text-center">
          {STATES_MAP.CANCELLED.label} Order
        </DialogTitle>

        <Container>
          <Row className="py-2">
            <div className="col-6 h4"> Decline Reason </div>
          </Row>
          <Row className="pt-4">
            <div className="col ">
              <InputGroup fullWidth>
                <textarea placeholder="Type hear" onChange={onChange} />
              </InputGroup>
            </div>
          </Row>
          <Row className="my-2 pt-5">
            <Col className="col-12 ">
              <Checkbox
                name="notifyCustomer"
                status="Info"
                onChange={(value) => setData({ ...data, notify: value })}
                checked={data.notify}
              >
                <span className="text-muted">
                  Notify the customer of the order{" "}
                </span>
              </Checkbox>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="col-6">
              <Button appearance="ghost" status="Basic" onClick={handleClose}>
                Cancel
              </Button>
            </Col>

            <Col className="col-6 d-flex justify-content-end">
              <Button
                onClick={handleSumbit}
                disabled={disableButton}
                status={STATES_MAP.CANCELLED.color}
              >
                {STATES_MAP.CANCELLED.label} Order
              </Button>
            </Col>
          </Row>
        </Container>
      </Dialog>
    </>
  );
};

export default CancelState;
