import styled, { css } from "styled-components";

export const StyledTable = styled("table")`
  border-spacing: 0;
  width: 100%;
  tbody tr:nth-child(2n) {
    background-color: ${(props) => props.theme.backgroundBasicColor2};
  }
  tbody tr:hover {
    background: ${(props) => props.theme.backgroundBasicColor3} !important;
  }

  thead tr {
    background: ${(props) => props.theme.backgroundBasicColor2};
    th {
      border-top: 1px solid ${(props) => props.theme.backgroundBasicColor3};
      border-left: 1px solid ${(props) => props.theme.backgroundBasicColor3};
      :last-child {
        border-right: 1px solid ${(props) => props.theme.backgroundBasicColor3};
      }
    }
  }

  ${(props) =>
    props.footerRow &&
    css`
      tr {
        :last-child {
          td {
            text-align: start;
            border: 1px solid ${(props) => props.theme.backgroundBasicColor2};
          }
        }
      }
    `}

  td div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  th,
  td {
    max-width: ${({ columnSize }) => (columnSize > 150 ? columnSize : 150)}px;
    margin: 0;
    padding: 0.5rem;
    border-top: 1px solid ${(props) => props.theme.backgroundBasicColor2};
    border-left: 1px solid ${(props) => props.theme.backgroundBasicColor2};
    text-align: center;
    :last-child {
      border-right: 1px solid ${(props) => props.theme.backgroundBasicColor2};
    }
  }
`;
