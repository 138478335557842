import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { SidebarBody, Sidebar } from "@paljs/ui/Sidebar";
import { Menu } from "@paljs/ui/Menu";
import useMenuItem from "../useMenuItem";
import { Link } from "gatsby";
import { Location } from "@reach/router";

export const getPathReady = (path) => {
  return path.endsWith("/") ? path.slice(0, -1) : path;
};

const SidebarCustom = (props, ref) => {
  const sidebarRef = useRef(null);
  const menuRef = useRef(null);

  const menuItems = useMenuItem();
  useImperativeHandle(ref, () => ({
    toggle() {
      if (sidebarRef.current) sidebarRef.current.toggle();
    },
  }));

  // "hidden" | "visible" | "compacted" | "expanded"
  const getState = (state) => {};

  return (
    <Sidebar
      getState={getState}
      ref={sidebarRef}
      property="start"
      containerFixed
      responsive
      className="menu-sidebar"
    >
      <SidebarBody>
        <Location>
          {({ location }) => (
            <Menu
              className="sidebar-menu"
              Link={Link}
              ref={menuRef}
              items={menuItems}
              currentPath={getPathReady(location.pathname)}
              toggleSidebar={() => sidebarRef.current.hide()}
            />
          )}
        </Location>
      </SidebarBody>
    </Sidebar>
  );
};

export default forwardRef(SidebarCustom);
