import { useState, useEffect, useContext } from "react";
import { useLocalStorage, useFirebase } from "../../../../hooks";
import _ from "lodash";
import produce from "immer";
import { useNotification as useMutationNotification } from "../../../../mutations";
import useUtils from "./useUtils";
import { GlobalContext } from "../../index";

const defaultSettings = {
  permission: false,
  subscribe: false,
  playSound: false,
  notificationID: null,
  repeatNotification: 5, // in seconds
};

const useNotification = () => {
  const firebase = useFirebase();
  const [messaging, setMessaging] = useState(null);
  const { setNewOrders } = useContext(GlobalContext);
  const [mutatoinData, deleteError, setMutation, setDeleteMutation] =
    useMutationNotification();

  const [savedSettings, setSavedSettings] = useLocalStorage(
    "NotificationSavedSettings",
    _.pick(defaultSettings, ["permission", "subscribe", "notificationID"])
  );
  const [settings, setSettings] = useState(
    produce(defaultSettings, (draft) => {
      _.forEach(savedSettings, (v, k) => {
        draft[k] = v;
      });
    })
  );

  const { unSubscribe, subscribe } = useUtils({
    messaging,
    settings,
    setSettings,
    setMutation,
    setDeleteMutation,
  });
  useEffect(() => {
    if (settings)
      setSavedSettings(
        _.pick(settings, ["permission", "subscribe", "notificationID"])
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const payloadMessage = (payload) => {
    console.log("Message received. ", payload);
    const orderId = payload.data.orderId;

    console.log(orderId);

    setNewOrders((o) => {
      return [...o, orderId];
    });
  };
  // SET FIREBASE MESSAGING
  useEffect(() => {
    if (messaging) return;

    (async () => {
      if (!firebase) return;
      await import("firebase/messaging");
      const _m = await firebase.messaging();

      _m.onMessage(payloadMessage);
      setMessaging(_m);
    })();
  });

  useEffect(() => {
    if (_.isEmpty(mutatoinData)) return;

    setSettings((s) => {
      return {
        ...s,
        notificationID: mutatoinData.createNotification.notification.id,
      };
    });
  }, [mutatoinData]);

  return { settings, unSubscribe, subscribe };
};

export default useNotification;
