import React, { useMemo } from "react";
import _ from "lodash";

import { Card, CardBody, CardHeader } from "@paljs/ui/Card";

const DeliveryInfo = ({ order }) => {
  const support = useMemo(() => {
    return order.deliver?.driverNetwork?.support;
  }, [order]);
  return (
    <>
      {!_.isEmpty(order.deliver) && (
        <>
          <Card>
            <CardHeader>
              <div className="row">
                <div className="col h6">
                  {" "}
                  Delivery Status -{" "}
                  <a href={`${order.deliver?.trackURL}`}>
                    <span className="text-muted">{order.deliver?.state}</span>
                  </a>
                </div>
              </div>
            </CardHeader>
            {support && (
              <CardBody>
                <div className="row">
                  <div className="col text-capitalize">
                    <b>Contact Support:</b>
                  </div>
                </div>

                <div className="row py-1">
                  <div className="col text-capitalize">
                    {`${support?.firstname || ""} 
                ${support?.lastname || ""}`}
                  </div>
                </div>
                <div className="row py-1">
                  <div className="col-12">
                    <a href={`mailto:${support?.email}`}>{support?.email}</a>
                  </div>
                </div>
                <div className="row py-1">
                  <div className="col-12">
                    <a href={`tel:${support?.phone}`}>{support?.phone}</a>
                  </div>
                </div>
              </CardBody>
            )}
          </Card>
        </>
      )}
    </>
  );
};

export default DeliveryInfo;
