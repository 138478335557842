import React, { useState } from "react";
import { useSessionStorage } from "../../hooks";

const GlobalContext = React.createContext({});

const GlobalContextProvider = ({ children }) => {
  const [restaurant, setResturant] = useState({});
  const [newOrders, setNewOrders] = useSessionStorage("NEW_ORDER", []);

  return (
    <GlobalContext.Provider
      value={{ restaurant, setResturant, newOrders, setNewOrders }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
export { GlobalContextProvider, GlobalContext };
