import axios from "axios";
import { getUserInfo } from "../context/userAuth";

const axiosPost = async (path, data) => {
  const user = getUserInfo();

  return await axios.post(`${process.env.GATSBY_STRAPI_URL}/${path}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: user
        ? user.accessToken
          ? `Bearer ${user.accessToken}`
          : ""
        : "",
    },
  });
};

export { axiosPost };
