import React, { useEffect, useContext } from "react";
import { Router } from "@reach/router";
import Orders from "../containers/Orders";
import Offers from "../containers/Offers";
import EditOrder from "../containers/EditOrder";
import Settings from "../containers/Settings";
import Files from "../containers/Settings/Files";
import ContextProviders from "../context/admin";
import { EmployeeContext } from "../context";
import PrivateRoute from "../components/PrivateRoute";
import { isBrowser } from "../utils/auth";

const Home = () => {
  const { employee } = useContext(EmployeeContext);

  // Start of Tawk.to Script
  useEffect(() => {
    if (!employee || !isBrowser()) return;

    window.Tawk_API = {
      visitor: {
        name: employee.displayName,
        email: employee.email,
      },
    };
  }, [employee]);
  // End of Tawk.to Script

  return (
    <>
      <ContextProviders>
        <Router basepath={`/${process.env.GATSBY_ADMIN_URL}`}>
          <PrivateRoute default path="/orders" component={Orders} />
          <PrivateRoute path="/offers" component={Offers} />
          <PrivateRoute path="/orders/:id" component={EditOrder} />
          <PrivateRoute path="/settings" component={Settings} />
          <PrivateRoute path="/settings/files" component={Files} />
          <PrivateRoute path="/" component={Orders} />
        </Router>
      </ContextProviders>
    </>
  );
};
export default Home;
