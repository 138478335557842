const defaultSettings = {
  pageSizeDefault: 10,
  pageSizeOptions: [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ],
  paginationOptions: 4,
};
export default defaultSettings;
