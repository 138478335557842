import React, { useState, useContext, useMemo } from "react";
import { Button } from "@paljs/ui/Button";
import Container from "@paljs/ui/Container";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Checkbox } from "@paljs/ui/Checkbox";
import _ from "lodash";
import { STATES_MAP } from "../../data";
import { GlobalContext } from "../../../../context/admin";

const ReadyForDeliveryState = ({ onSumbit, order }) => {
  const [disableButton, setDisableButton] = useState(true);

  const { restaurant } = useContext(GlobalContext);

  const enableDeliverNotify = useMemo(() => {
    return restaurant?.settings?.features?.enableDeliverNotify || false;
  }, [restaurant]);

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({
    notify: true,
    notifyDriver: !_.isEmpty(order.deliver) && enableDeliverNotify,
  });

  const handleSumbit = () => {
    setDisableButton(true);
    setIsOpen(false);
    onSumbit(data, "READY_FOR_DELIVERY");
  };

  const handleClose = () => {
    setDisableButton(true);
    setIsOpen(false);
  };

  return (
    <>
      <Button
        status={STATES_MAP.READY_FOR_DELIVERY.color}
        onClick={() => {
          setIsOpen(true);
          setDisableButton(false);
        }}
      >
        {STATES_MAP.READY_FOR_DELIVERY.label}
      </Button>

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-ready-order"
        open={isOpen}
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-ready-order" className="text-center">
          Order {STATES_MAP.READY_FOR_DELIVERY.label}
        </DialogTitle>

        <Container>
          <Row className="my-2 pt-5">
            <Col className="col-6 ">
              <Checkbox
                name="notifyCustomer"
                status="Info"
                onChange={(value) => setData({ ...data, notify: value })}
                checked={data.notify}
              >
                <span className="text-muted">
                  Notify the customer of the order{" "}
                </span>
              </Checkbox>
            </Col>
            {enableDeliverNotify && (
              <Col className="col-6 text-end ">
                <Checkbox
                  name="notifyDriver"
                  status="Danger"
                  disabled={_.isEmpty(order.deliver)}
                  onChange={(value) =>
                    setData({ ...data, notifyDriver: value })
                  }
                  checked={data.notifyDriver}
                >
                  <span className="text-muted">Notify Driver of the order</span>
                </Checkbox>
              </Col>
            )}
          </Row>

          <Row className="pb-2">
            <Col className="col-6">
              <Button appearance="ghost" status="Basic" onClick={handleClose}>
                Cancel
              </Button>
            </Col>

            <Col className="col-6 d-flex justify-content-end">
              <Button
                onClick={handleSumbit}
                disabled={disableButton}
                status={STATES_MAP.READY_FOR_DELIVERY.color}
              >
                {STATES_MAP.READY_FOR_DELIVERY.label}
              </Button>
            </Col>
          </Row>
        </Container>
      </Dialog>
    </>
  );
};

export default ReadyForDeliveryState;
