import React, { useState } from "react";
import { Button } from "@paljs/ui/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CancelState from "../CancelState";
import { withStyles } from "@mui/styles";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const MoreActionsButton = ({ onSumbit }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        onClick={handleClick}
        appearance="ghost"
        status="Basic"
        size="Small"
        className="p-0"
      >
        More actions
      </Button>
      <StyledMenu
        id="more-Actions-menu"
        anchorEl={anchorEl}
        variant="menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleClose}>
          <CancelState onSumbit={onSumbit} />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

export default MoreActionsButton;
