import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Select from "@paljs/ui/Select";

import { EmployeeContext } from "../../../../context";
import { GlobalContext } from "../../../../context/admin";

import _ from "lodash";

const SelectRestaurant = () => {
  const { employee } = useContext(EmployeeContext);
  const { restaurant, setResturant } = useContext(GlobalContext);
  const [restaurantsOption, setRestaurantsOption] = useState([]);
  useEffect(() => {
    if (_.isEmpty(employee)) return;
    if (_.isEmpty(employee.restaurants)) return;

    setRestaurantsOption(
      employee.restaurants.map((v) => {
        return { label: v.name, value: v };
      })
    );
    setResturant(employee.restaurants[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  return (
    <>
      {!_.isEmpty(restaurantsOption) && (
        <>
          {restaurantsOption.length === 1 ? (
            <>{restaurant.name}</>
          ) : (
            <SelectStyled
              options={restaurantsOption}
              status="Basic"
              isMulti={false}
              value={restaurantsOption.find(
                (option) => option.label === restaurant.name
              )}
              onChange={({ value }) => setResturant(value)}
            />
          )}
        </>
      )}
    </>
  );
};

const SelectStyled = styled(Select)`
  min-width: 220px;
`;

export default SelectRestaurant;
