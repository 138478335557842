import _ from "lodash";
import { useContext } from "react";
import { EmployeeContext } from "../../../index";
import { useAlert } from "react-alert";

const useUtils = ({
  messaging,
  settings,
  setSettings,
  setMutation,
  setDeleteMutation,
}) => {
  const { employee } = useContext(EmployeeContext);
  const alert = useAlert();

  const subscribe = async () => {
    if (!messaging) return;
    if (!_.isNull(settings.notificationID)) return;
    const permission = requestPermission();
    const token = await getToken();
    if (permission && token) {
      console.log(token);
      setSettings({ ...settings, subscribe: true });
    }
  };

  const unSubscribe = async () => {
    if (settings.notificationID)
      setDeleteMutation({ variables: { id: settings.notificationID } });

    setSettings({ ...settings, notificationID: null, subscribe: false });
  };

  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");
        setSettings({ ...settings, permission: true });
        return true;
      } else {
        // Show permission request.
        console.log("Unable to get permission to notify.");
        alert(
          "In order to be notify, you need to allow Notification on this site "
        );
      }
    } catch (err) {
      alert.error("An error occurred while requestPermission. ");
      console.log(err);
      throw new Error(err);
    }
    return false;
  };

  const getToken = async () => {
    try {
      const currentToken = await messaging.getToken({
        vapidKey: process.env.GATSBY_FIREBASE_MESSAGEING_PUBLIC_VAPID_KEY,
      });
      if (currentToken) {
        console.log(currentToken);
        setMutation({
          variables: {
            token: currentToken,
            user: employee.id,
            deviceInfo: navigator.userAgent,
          },
        });
        return currentToken;
      } else {
        alert.error(
          "No registration token available. Request permission to generate one."
        );
      }
    } catch (err) {
      alert.error("An error occurred while retrieving token");
      console.log(err);
      throw new Error(err);
    }

    // [END get_token]
  };

  return { getToken, requestPermission, subscribe, unSubscribe };
};

export default useUtils;
