import { styled } from "@mui/material/styles";

const Label = styled("span")`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

export default Label;
