import React from "react";
import { Link } from "gatsby";
import { styled } from "@mui/material/styles";
import { LayoutHeader } from "@paljs/ui/Layout";
import { Actions } from "@paljs/ui/Actions";
import _ from "lodash";
import { breakpointDown } from "@paljs/ui/breakpoints";
import SelectRestaurant from "./selectRestaurant";
import BetterRestoLogo from "../../../BetterRestoLogo";
import NotficationDismiss from "../../../NotificationDismiss";

const HeaderStyle = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${breakpointDown("sm")`
    .right{
      display: none;
    }
  `}
  .right > div {
    height: auto;
    display: flex;
    align-content: center;
  }
  .logo {
    font-size: 1.25rem;
    white-space: nowrap;
    text-decoration: none;
  }
  .left {
    display: flex;
    align-items: center;
    .github {
      font-size: 18px;
      margin-right: 5px;
    }
  }
`;

const Header = (props) => {
  return (
    <LayoutHeader fixed>
      <HeaderStyle>
        <Actions
          size="Medium"
          actions={[
            {
              icon: { name: "menu-2-outline" },
              url: {
                onClick: props.toggleSidebar,
              },
            },
            {
              content: (
                <Link to={`/${process.env.GATSBY_ADMIN_URL}`} className="logo">
                  <BetterRestoLogo />
                </Link>
              ),
            },
            {
              content: <SelectRestaurant />,
            },
          ]}
        />
        <div className="mr-auto">
          <NotficationDismiss />
        </div>

        {!_.isEmpty(process.env.GATSBY_ENV) && (
          <div className="mx-auto h4 text-danger d-none d-sm-block">
            &gt;&gt;&gt;&gt;&gt;&gt; {_.upperCase(process.env.GATSBY_ENV)}{" "}
            &lt;&lt;&lt;&lt;&lt;&lt;
          </div>
        )}
        <Link to={`/logout`}>Log out</Link>
      </HeaderStyle>
    </LayoutHeader>
  );
};
export default Header;
