import React, { useEffect, useState } from "react";

import _ from "lodash";

import { useUpdateOrderState } from "../../../mutations";
import { navigate } from "@reach/router";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

import ApproveState from "./ApproveState";
import ReadyForPickupState from "./ReadyForPickupState";
import ReadyForDeliveryState from "./ReadyForDeliveryState";
import PickedUpState from "./PickedUpState";
import DeliverdState from "./DeliverdState";
import OutForDeliveryState from "./outForDeliveryState";
import MoreActionsButton from "./MoreActionsButton";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const OrderStates = ({ order }) => {
  const classes = useStyles();

  const [backdropOpen, setBackdropOpen] = useState(false);

  const [
    dataUpdateOrderState,
    loadingUpdateOrderState,
    errorUpdateOrderState,
    setUpdateOrderState,
  ] = useUpdateOrderState();

  const onSumbit = (data, requestState) => {
    if (_.isEmpty(data)) return;

    setBackdropOpen(true);

    setUpdateOrderState({
      variables: {
        ...data,
        id: order.id,
        event: requestState === "CANCELLED" ? "cancel" : "next",
      },
    });
  };

  useEffect(() => {
    if (loadingUpdateOrderState) return;
    if (_.isEmpty(dataUpdateOrderState)) return;

    setBackdropOpen(false);
    navigate(0);
  }, [dataUpdateOrderState, loadingUpdateOrderState]);

  useEffect(() => {
    if (_.isEmpty(errorUpdateOrderState)) return;
    const err = JSON.stringify(errorUpdateOrderState);
    throw new Error(err);
  }, [errorUpdateOrderState]);

  return (
    <>
      <span className="pr-2">
        <MoreActionsButton onSumbit={onSumbit} />
      </span>
      {order.state === "PENDING" && (
        <>
          <ApproveState onSumbit={onSumbit} order={order} />
        </>
      )}
      {order.state === "APPROVED" && (
        <>
          {order.type === "pickup" && (
            <>
              <ReadyForPickupState onSumbit={onSumbit} />
            </>
          )}
          {order.type === "delivery" && (
            <>
              <ReadyForDeliveryState onSumbit={onSumbit} order={order} />
            </>
          )}
        </>
      )}
      {order.state === "READY_FOR_PICK_UP" && (
        <>
          <PickedUpState onSumbit={onSumbit} />
        </>
      )}
      {order.state === "READY_FOR_DELIVERY" && (
        <>
          <OutForDeliveryState onSumbit={onSumbit} />
        </>
      )}
      {order.state === "OUT_FOR_DELIVERY" && (
        <>
          <DeliverdState onSumbit={onSumbit} />
        </>
      )}

      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default OrderStates;
