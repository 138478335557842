import React, { useMemo } from "react";
import _ from "lodash";
import Badge from "@paljs/ui/Badge";
import { EvaIcon } from "@paljs/ui/Icon";

import { Card, CardBody, CardHeader } from "@paljs/ui/Card";

const riskObj = {
  normal: {
    badgeStatus: "Success",
  },
  elevated: {
    badgeStatus: "Warning",
  },
  highest: {
    badgeStatus: "Danger",
  },
};

const Transaction = ({ order }) => {
  const checksPayment = useMemo(() => {
    if (_.isEmpty(order)) return;

    return order.transaction?.lastCharge?.charge?.payment_method_details?.card
      ?.checks;
  }, [order]);
  return (
    <>
      {!_.isEmpty(order) && order.transaction && (
        <>
          <Card>
            <CardHeader>Fraud analysis</CardHeader>
            <CardBody>
              <div className="row  pb-3">
                <div className="col-2">
                  {order.transaction.riskScore > 0 && (
                    <Badge
                      status={
                        riskObj[order?.transaction?.riskLevel]?.badgeStatus
                      }
                      className=""
                      position="topLeft"
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        padding: 5,
                        left: "auto",
                      }}
                    >
                      {order.transaction.riskScore}
                    </Badge>
                  )}
                </div>
                <div className="col-10 text-capitalize">
                  {order.transaction.riskLevel}
                </div>
              </div>
              <div className="row py-1">
                <div className="col-2">
                  <EvaIcon
                    status={
                      checksPayment.address_postal_code_check === "pass"
                        ? "Success"
                        : "Danger"
                    }
                    name={
                      checksPayment.cvc_check === "pass"
                        ? "checkmark-circle"
                        : "alert-circle"
                    }
                  />
                </div>
                <div className="col-10"> Zip / Postal Code check</div>
              </div>
              <div className="row py-1">
                <div className="col-2">
                  <EvaIcon
                    status={
                      checksPayment.cvc_check === "pass" ? "Success" : "Danger"
                    }
                    name={
                      checksPayment.cvc_check === "pass"
                        ? "checkmark-circle"
                        : "alert-circle"
                    }
                  />
                </div>
                <div className="col-10">CVC check</div>
              </div>
              <div className="row  py-1">
                <div className="col-2">
                  <EvaIcon
                    status={
                      checksPayment.address_line1_check === "pass"
                        ? "Success"
                        : "Danger"
                    }
                    name={
                      checksPayment.cvc_check === "pass"
                        ? "checkmark-circle"
                        : "alert-circle"
                    }
                  />
                </div>
                <div className="col-10">Street check</div>
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
};

export default Transaction;
