import React, { useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useOrderByNumber } from "../../queries";

import { useLocation } from "@reach/router";
import Container from "@paljs/ui/Container";
import { GlobalContext } from "../../context/admin";
import BackButton from "../../components/BackButton";
import OrderStates from "./OrderStates";
import Items from "./Items";
import OrderProgressBar from "./OrderProgressBar";
import CustomerInfo from "./CustomerInfo";
import ShippingAddress from "./ShippingAddress";
import Transaction from "./Transaction";
import Seo from "../../components/SEO";
import { useAlert } from "react-alert";
import DeliveryInfo from "./DeliverInfo";

const EditOrder = () => {
  const location = useLocation();
  const orderNumber = useMemo(() => {
    return _.parseInt(_.last(_.split(location.pathname, "/")));
  }, [location]);

  const { restaurant } = useContext(GlobalContext);
  const [orderData, loadingOrder, orderError, executeOrder] =
    useOrderByNumber();

  const [order, setOrder] = useState({});
  const alert = useAlert();

  useEffect(() => {
    if (orderNumber < 1) return;
    if (_.isEmpty(restaurant)) return;
    if (_.isUndefined(restaurant)) return;
    if (!_.isObject(restaurant)) return;

    executeOrder({
      number: orderNumber,
      restaurantId: restaurant.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNumber, restaurant]);

  useEffect(() => {
    if (loadingOrder) return;
    if (!_.isEmpty(orderError)) {
      console.log(JSON.stringify(orderError));
      if (orderError.message) alert.error(orderError.message);
      return;
    }
    if (_.isEmpty(orderData)) return;
    if (_.isEmpty(orderData.orderByNumber)) return;

    setOrder(orderData.orderByNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData, loadingOrder, orderError]);

  return (
    <>
      <Seo title={`Orders/${orderNumber}`} />
      {!_.isEmpty(order) && (
        <>
          <Container fluid={true}>
            <div className="row pb-3 mb-3">
              <div className="col-1 ">
                <BackButton />
              </div>
              <div className="col-11 ">
                <OrderProgressBar order={order} />
              </div>
            </div>

            <div className="row py-2">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-md-3 h4">{order.displayOrderId}</div>
                  <div className="col-md-1 d-md-block d-none h4">|</div>
                  <div className="col-md-8 h4">
                    <span className="h5" style={{ fontWeight: 400 }}>
                      {_.capitalize(order.type)} Time:
                    </span>
                    <span
                      className="pl-2 h5"
                      style={{
                        fontWeight: 500,
                        color:
                          order.scheduleTime?.display !== "ASAP"
                            ? "red"
                            : "black",
                      }}
                    >
                      {order.scheduleTime?.display.toUpperCase() || ""}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-lg-end pt-md-3">
                <OrderStates order={order} />
              </div>
            </div>

            <div className="row">
              <div className="col-xl-8">
                <Items order={order} />
              </div>
              <div className="col-xl-4">
                {!_.isEmpty(order.deliver) && (
                  <div className="row">
                    <div className="col-12">
                      <DeliveryInfo order={order} />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12">
                    <CustomerInfo order={order} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <ShippingAddress order={order} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Transaction order={order} />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default EditOrder;
