import React, { useCallback, useMemo } from "react";
import moment from "moment";
import ItemPopover from "./itemPopover";
import _ from "lodash";

const useTableHeader = () => {
  const timeFromNow = useCallback((value) => {
    if (_.isEmpty(value)) return "";

    const time = moment(value);
    const diff = moment().diff(time);

    const seconds = diff / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;

    if (seconds < 60) return `${_.toInteger(seconds)} Seconds ago`;
    if (minutes < 60) return `${_.toInteger(minutes)} Minutes ago`;
    if (hours < 3) return `${_.toInteger(hours)} hours ago`;

    return time.format("MMM Do YY - h:mm A");
  }, []);

  return useMemo(
    () => [
      { accessor: "displayOrderId", Header: "Order" },
      {
        accessor: "created_at",
        Header: "Date",
        Cell: ({ value }) => {
          return timeFromNow(value);
          // return value ? moment(value).format("YYYY MMM Do h:mm A") : "";
        },
      },
      {
        accessor: "location",
        Header: "Location",
      },
      {
        accessor: "customer",
        Header: "Customer",
      },
      {
        accessor: "type",
        Header: "Type",
      },
      {
        accessor: "state",
        Header: "State",
      },
      {
        accessor: "items",
        Header: "Items",
        Cell: ({ value }) =>
          value ? _.isArray(value) ? <ItemPopover items={value} /> : value : "",
      },
      {
        accessor: "total",
        Header: "Total",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export default useTableHeader;
