import React, { useEffect, useState, useContext } from "react";
import { Button } from "@paljs/ui/Button";
import Container from "@paljs/ui/Container";
import Row from "@paljs/ui/Row";
import Col from "@paljs/ui/Col";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import _ from "lodash";
import { Checkbox } from "@paljs/ui/Checkbox";

import produce from "immer";
import { STATES_MAP } from "../../data";
import { GlobalContext } from "../../../../context/admin";
import { useMemo } from "react";
import ApproveStateButton from "./button";

const ApproveState = ({ onSumbit, order }) => {
  const [disableButton, setDisableButton] = useState(true);
  const [resetButtonDisable, setResetButtonDisable] = useState(true);
  const { restaurant } = useContext(GlobalContext);

  const enableDeliverNotify = useMemo(() => {
    return restaurant?.settings?.features?.enableDeliverNotify || false;
  }, [restaurant]);

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({
    preparationTime: -1,
    notify: true,
    notifyDriver: enableDeliverNotify && order.type === "delivery",
  });

  const handleSumbit = () => {
    setDisableButton(true);
    setIsOpen(false);
    onSumbit(data, "APPROVED");
  };

  const handleClose = () => {
    setDisableButton(true);
    setIsOpen(false);
  };

  const [preparationTime, setPreparationTime] = useState({
    totalMinutes: 0,
    displayValue: "0 Min",
    hours: 0,
  });

  const onReset = () => {
    setPreparationTime({
      totalMinutes: 0,
      displayValue: "0 Min",
      hours: 0,
    });
    setDisableButton(true);
    setResetButtonDisable(true);
  };

  const onAddMinutes = (value) => {
    setDisableButton(false);
    setResetButtonDisable(false);
    setPreparationTime(
      produce(preparationTime, (draft) => {
        draft.totalMinutes += value;
        draft.hours = _.toInteger(draft.totalMinutes / 60);
        if (draft.hours === 0)
          draft.displayValue = `${draft.totalMinutes}  Min`;
        else
          draft.displayValue = `${draft.hours}  H: ${
            draft.totalMinutes - draft.hours * 60
          } M `;
      })
    );
  };

  useEffect(() => {
    if (_.isEmpty(preparationTime)) return;
    setData((d) => {
      return {
        ...d,
        preparationTime: preparationTime.totalMinutes,
      };
    });
  }, [preparationTime]);

  return (
    <>
      <ApproveStateButton setIsOpen={setIsOpen} />

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-approve-order"
        open={isOpen}
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-approve-order" className="text-center">
          {STATES_MAP.APPROVED.label} Order
        </DialogTitle>

        <Container>
          <Row className="py-2">
            <div className="col-6 h4">Preparation Time: </div>
            <div className="col-6 text-end h5 text-primary">
              {/* <span

                style={{ cursor: "pointer", fontSize: 15 }}
              > */}
              <Button
                onClick={onReset}
                className="mx-4"
                appearance="hero"
                shape="Round"
                status="Warning"
                size="Small"
                disabled={resetButtonDisable}
              >
                Reset
              </Button>

              {/* <EvaIcon
                  className="mx-4"
                  status="Info"
                  name="minus-outline"
                  options={{
                    animation: { type: "pulse" },
                  }}
                /> */}
              {/* </span> */}

              {preparationTime.displayValue}
            </div>
          </Row>
          <Row className="pt-4">
            <div className="col-lg-12 col-md-12 ">
              <div className="row">
                {[5, 15, 30].map((v, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="col-4">
                        <Button
                          size="Medium"
                          appearance="outline"
                          status="Info"
                          onClick={() => {
                            onAddMinutes(v);
                          }}
                        >
                          Add {v} mins
                        </Button>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </Row>

          <Row className="my-2 pt-5">
            <Col className="col-6 ">
              <Checkbox
                name="notifyCustomer"
                status="Info"
                onChange={(value) => setData({ ...data, notify: value })}
                checked={data.notify}
              >
                <span className="text-muted">
                  Notify the customer of the order{" "}
                </span>
              </Checkbox>
            </Col>
            {enableDeliverNotify && order.type === "delivery" && (
              <Col className="col-6 text-end ">
                <Checkbox
                  name="notifyDriver"
                  status="Danger"
                  onChange={(value) =>
                    setData({ ...data, notifyDriver: value })
                  }
                  checked={data.notifyDriver}
                >
                  <span className="text-muted">Notify Driver of the order</span>
                </Checkbox>
              </Col>
            )}
          </Row>

          <Row className="py-2">
            <Col className="col-6">
              <Button appearance="ghost" status="Basic" onClick={handleClose}>
                Cancel
              </Button>
            </Col>

            <Col className="col-6 d-flex justify-content-end">
              <Button
                onClick={handleSumbit}
                disabled={disableButton}
                status={STATES_MAP.APPROVED.color}
              >
                {STATES_MAP.APPROVED.label} Order
              </Button>
            </Col>
          </Row>
        </Container>
      </Dialog>
    </>
  );
};

export default ApproveState;
