import React, { memo, useState, useEffect, useContext } from "react";

import Seo from "../../components/SEO";
import { Tabs, Tab } from "@paljs/ui/Tabs";
import { Card } from "@paljs/ui/Card";

import { useOrders } from "../../queries";
import _ from "lodash";
import useOrderDataMap from "./utils/useOrderDataMap";
import useTable from "./utils/useTable";
import { useAlert } from "react-alert";
import { GlobalContext } from "../../context/admin";

const Orders = () => {
  const {
    data: ordersData,
    loading: loadingOrders,
    error: ordersError,
    onExecuteQuery: executeOrder,
    refetch: refetchOrders,
  } = useOrders();

  const { mapOrdersData, totalOrders } = useOrderDataMap({
    ordersData,
    loadingOrders,
  });

  const { orderQueryVariables, tableElement, onFilter } = useTable({
    mapOrdersData,
    loadingOrders,
    totalOrders,
  });
  const alert = useAlert();

  const [activeTab, setActiveTab] = useState(0);
  const { newOrders } = useContext(GlobalContext);

  // executeOrder
  useEffect(() => {
    if (_.isEmpty(orderQueryVariables)) return;
    executeOrder(orderQueryVariables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderQueryVariables]);

  useEffect(() => {
    let obj = { archive: false };
    if (activeTab === 1) obj = { archive: true };

    onFilter(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (_.isEmpty(ordersError)) return;

    console.log(JSON.stringify(ordersError));
    if (ordersError.message) {
      console.log(ordersError.message);
      alert.error(ordersError.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersError]);

  useEffect(() => {
    if (_.isEmpty(newOrders) || !_.isFunction(refetchOrders)) return;
    console.log("newOrders", "refetchOrders", newOrders);
    refetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOrders]);
  return (
    <>
      <Seo title="Orders" />
      <Card>
        <Tabs
          onSelect={(i) => {
            console.log(i);
            setActiveTab(i);
          }}
        >
          <Tab title="Active Orders">{tableElement}</Tab>
          <Tab title="Archive Orders">{tableElement}</Tab>
        </Tabs>
      </Card>
    </>
  );
};

export default memo(Orders);
