import { gql, useMutation } from "@apollo/client";

const useUpdateOrderState = () => {
  const MUTATE = gql`
    mutation UpdateOrderState(
      $id: ID!
      $event: ENUM_ORDERS_EVENT!
      $cancelReason: String
      $preparationTime: Int
      $notify: Boolean
      $notifyDriver: Boolean
    ) {
      updateOrderState(
        input: {
          where: { id: $id }
          data: {
            event: $event
            preparationTime: $preparationTime
            cancelReason: $cancelReason
            notify: $notify
            notifyDriver: $notifyDriver
          }
        }
      ) {
        order {
          id
        }
      }
    }
  `;

  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return [data, loading, error, setData];
};

export default useUpdateOrderState;
