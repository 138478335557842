import { gql, useMutation } from "@apollo/client";

const useDeleteMedia = () => {
  const MUTATE = gql`
    mutation DeleteMedia($id: ID!, $mediaId: ID!) {
      deleteMedia(input: { where: { id: $id } }, mediaId: $mediaId) {
        name
      }
    }
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useDeleteMedia;
