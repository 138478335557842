import React, { useRef, useEffect, useContext } from "react";
import _ from "lodash";
import { GlobalContext, SettingsContext } from "../../context/admin";

const Audio = ({ playSound }) => {
  const audioRef = useRef(null);
  const { newOrders } = useContext(GlobalContext);
  const { notificationSettings: notification } = useContext(SettingsContext);

  const _playSound = () => {
    if (_.isEmpty(audioRef) || _.isEmpty(audioRef.current)) return;
    audioRef.current.play();
  };

  useEffect(() => {
    if (_.isEmpty(newOrders)) return;
    _playSound();
    const interval = setInterval(() => {
      _playSound();
    }, _.toInteger(notification.settings.repeatNotification * 1000));
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOrders]);
  return (
    <>
      <audio preload="auto" ref={audioRef}>
        <source src="/audio/goes-without-saying-608.mp3" type="audio/mpeg" />
        <source src="/audio/goes-without-saying-608.ogg" type="audio/ogg" />
        <embed
          hidden={true}
          autostart="false"
          loop={false}
          src="/audio/goes-without-saying-608.mp3"
        />
      </audio>
    </>
  );
};

export default Audio;
