import { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../index";
import { useLocalStorage } from "../../../hooks";

import _ from "lodash";
import produce from "immer";

const defaultSettings = {
  idFormatPrefix: "",
  idFormatSuffix: "",
  idStartAt: 1001,
  orderDetails: {
    items: {
      showImage: true,
      showUnitPriceForEachItem: true,
    },
  },
};

const useSettings = () => {
  const { restaurant } = useContext(GlobalContext);
  const [orderDetails, setOrderDetails] = useLocalStorage(
    "OrderDetailsSettings",
    defaultSettings.orderDetails
  );

  const [settings, setSettings] = useState({
    ...defaultSettings,
    orderDetails,
  });

  useEffect(() => {
    if (_.isEmpty(settings?.orderDetails)) return;
    setOrderDetails((s) =>
      produce(s, (draft) => {
        draft.items = settings?.orderDetails.items;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.orderDetails]);

  useEffect(() => {
    if (_.isEmpty(restaurant)) return;
    const _orderSetting = restaurant.settings.orders;
    setSettings((s) =>
      produce(s, (draft) => {
        draft.idFormatPrefix = _orderSetting.prefixId || "";
        draft.idFormatSuffix = _orderSetting.suffixId || "";
        draft.idStartAt = _orderSetting.startAt;
      })
    );
  }, [restaurant]);

  return [settings, setSettings];
};

export default useSettings;
