import React from "react";
import _ from "lodash";
import Badge from "@paljs/ui/Badge";

import Popover from "@paljs/ui/Popover";
import { Card, CardBody } from "@paljs/ui/Card";

const ItemPopover = ({ items }) => {
  return (
    <Popover
      trigger="hint"
      placement="left"
      style={{ width: "100%" }}
      overlay={
        <Card
          style={{
            marginBottom: "0",
            // maxHeight: "600px",
          }}
        >
          <CardBody>
            <div>
              {!_.isEmpty(items) &&
                items.map((v, i) => {
                  return (
                    <React.Fragment key={i}>
                      {i !== 0 && <hr />}
                      <div
                        className="row"
                        style={{ minWidth: "200px", height: "auto" }}
                      >
                        <div className="col-4  text-left">
                          {/* <StrapiImage src={v.item.photo.url} /> */}
                          <Badge status="Warning" position="topStart">
                            {v.quantity}
                          </Badge>
                        </div>
                        <div className="col-6 text-right">{v.item.name}</div>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          </CardBody>
        </Card>
      }
    >
      <div style={{ cursor: "default" }}>
        {!_.isEmpty(items) && items.length}
      </div>
    </Popover>
  );
};

export default ItemPopover;
