import React from "react";
import { EvaIcon } from "@paljs/ui/Icon";
import { Link } from "gatsby";

const BackButton = () => {
  return (
    <Link to="../" style={{ color: "black" }}>
      <EvaIcon className="mx-2 fs-1" name="arrow-back" />
    </Link>
  );
};

export default BackButton;
